<template>
  <div>
    <div
      :class="`chart-wrapper-${seed}`"
      class="chart-wrapper"
    >
      <div class="selector-wrapper">
        <Selector
          v-show="this.factory.config.endpoints.series.length > 0"
          class="multiselect--min-width multiselect--keep-placeholder chart-map-selector"
          :options="varXNames"
          :default="mapProperty"
          :value="mapProperty"
          :max-height="200"
          v-bind="groupProps"
          :placeholder="placeholderValue"
          @handleSelect="handleSelector($event)"
        />
        <IconInfo v-tooltip="iconInfoMapPlaceholder"/>
      </div>
      <SliderTimeline
        v-if="timeLine"
        :data="data"
        :container="`.chart-wrapper-${seed}`"
        @updateMap="handleUpdateData"
      />
      <div id="map-chart" ref="chart">
      </div>
    </div>
  </div>
</template>
<i18n src="../../locales/lineComponent.json"></i18n>
<script>
import { VisualizationMixin } from "../../assets/mixins";

import ChrolopletMap from "../../assets/charts/ChrolopletMap";
import SliderTimeline from "../SliderTimeline";
import Selector from "../selectors/Selector";
import IconInfo from "./IconInfo";

export default {
  name: "Map",
  components: {
    SliderTimeline,
    Selector,
    IconInfo
  },
  mixins: [VisualizationMixin],
  data() {
    return {
      data: [],
      chart: null,
      cacheMap: null,
      dataMap: [],
      mapProperty: '',
      mapPropertyInverted: false,
      placeholderValue: '',
      iconInfoMapPlaceholder: '',
      seed: Math.random().toString(36).substring(7)
    };
  },
  computed: {
    timeLine() {
      return this.data.length && this.showTimeLine
    }
  },
  props: {
    properties: Object,
    showTimeLine: {
      type: Boolean,
      default: false
    },
    language: {
      type: String,
      default: ''
    }
  },
  async mounted() {
    this.$i18n.locale = this.language
    const defaultSizes = {
      minHeight: 320,
      maxWidth: 1440,
      aspectRatio: 16 / 9
    };

    const props = {
      ...defaultSizes,
      ...this.properties,
      onElementClick: this.handleClick,
      ctx: this.$refs.chart,
      data: this.data,
    };

    this.chart = new ChrolopletMap(props);

    this.factory.config.charts[0].type = 'Map'
    this.mapProperty = this.factory.config.charts[0].mapProperty
    this.mapPropertyInverted = this.factory.config.charts[0].mapPropertyInverted
    await this.setChartData();
    if(this.factory.config.charts[0].data === "hiel") {
      this.placeholderValue = this.$t('selectorMapPlaceholderHIEL')
      this.iconInfoMapPlaceholder = this.$t('iconInfoMapPlaceholderHIEL')
    }
    if(this.factory.config.charts[0].data === "ahdi") {
      this.placeholderValue = this.$t('selectorMapPlaceholderAHDI')
      this.iconInfoMapPlaceholder = this.$t('iconInfoMapPlaceholderAHDI')
    }

    if(this.factory.config.charts[0].data === "observatorio_internacional") {
      this.placeholderValue = this.$t('selectorMapPlaceholderObservatory')
      this.iconInfoMapPlaceholder = this.$t('iconInfoMapPlaceholderObservatory')
    }
  },
  methods: {
    async setChartData() {
      const data = await this.fetchData();
      this.chart.data = data.raw
      this.data = data.raw
      this.chart.draw();
    },
    handleUpdateData(data, year) {
      this.chart.data = data
      this.chart.currentYear = year
      this.chart.updateMap(year);
    },
    handleClick(element, name) {
      //Pass the name of the country
      this.$emit("showMap", element, name);
    },
    handleSelector(serieSelected) {
      const valueSelected = this.factory.config.endpoints.series.filter(d => d.name === serieSelected)
      if(valueSelected[0]) {
        this.chart.mapProperty = valueSelected[0].id
        this.chart.mapPropertyInverted = this.factory.config.endpoints.series.find(d => d.id === valueSelected[0].id).inverted || false
        this.$root.$emit('updateMapProperty', valueSelected[0])
        this.placeholderValue = serieSelected
        this.chart.titleLegend = serieSelected
        this.setChartData()
        this.chart.updateChrolopletColors()
      }
    },
    getSeriesSelector(){
      return this.factory.config.endpoints.series.map(d => d.name)
    }
  }
};
</script>
<style>

.chart-wrapper {
  height: auto;
}

.title-graph {
  border-bottom: 1px solid grey;
  margin-left: .5rem;
  font-family: inherit;
}

.text-graph {
  font-size: 14px;
  font-family: inherit;
  width: 95%;
  margin-top: -15px;
  margin-left: 1rem;
  padding-bottom: 1rem;
}

#map-chart {
  border: 1px solid #E5E4E5;
  height: 100%;
}

.countries {
  cursor: pointer;
}

.countries,
.countries-no-data {
  stroke-width: 1px;
  stroke: #9B9A9B;
  stroke-opacity: .3;
}

.countries:hover {
  stroke: #111;
  stroke-opacity: 1;
}

.tooltip-map {
  width: max-content;
  background-color: #fff;
  position: absolute;
  display: none;
  opacity: 0;
  border: 1px solid #CACACA;
  padding: .5rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, .14);
  border-radius: 2px;
  box-sizing: border-box;
}

.tooltip-title {
  font-weight: 700;
  font-size: 16px;
  display: block;
  margin-bottom: .5rem;
}

.tooltip-text {
  font-size: 14px;
  margin-bottom: .5rem;
}

.tooltip-link {
  font-size: 14px;
  color: #558380;
  display: inline-block;
  text-decoration: underline;
  cursor: pointer;
}

.tooltip-footer {
  min-width: 200px;
  justify-content: space-between;
}

.time-year {
  font-size: 32px;
  font-weight: bold;
}

.zoom-message {
  padding: .5rem;
  border-radius: 4px;
  background-color: #ccc;
  width: max-content;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: max-content;
}

.zoom-message p {
  font-size: 14px;
  color: #000;
  text-align: center;
  margin: 0;
}

.selector-wrapper{
  width: 40%;
  min-width: 400px;
  display: flex;
  margin-bottom: 1rem;
  align-items: center;
  gap: .5rem;
}

@media only screen and (max-width: 525px) {

  .tooltip-map {
    width: 125px;
    padding: .5rem;
  }

  .tooltip-link,
  .tooltip-footer,
  .tooltip-text {
    display: block;
    margin-top: .25rem;
  }

  .selector-wrapper{
    width: 100%;
    min-width: auto;
  }
}

</style>
