<template>
  <div class="legend">
    <div
      :key="item.name" v-for="item in items"
      class="legend-item"
      :class="{ 'legend-item--selected': item.isSelected }"
      @click="handleClick(item)"
      @mouseenter="handleMouseenter(item)"
      @mouseout="handleMouseout(item)"
    >
      <div class="name">{{ item.name }}</div>
      <div class="close" @click.stop="handleCloseClick(item)" @mouseenter="handleMouseenter(item)" @mouseout.stop="handleMouseenter(item)">
        <v-icon name="times" scale="0.75"></v-icon>
      </div>
    </div>
  </div>
</template>

<script>
import "vue-awesome/icons/times";
import Icon from "vue-awesome/components/Icon";
import { debounce } from "debounce";

export default {
  components: {
    "v-icon": Icon
  },
  props: {
    items: Array,
    color: Function
  },
  created() {
    this.handleMouseenter = debounce(this.handleMouseenter, 250);
    this.handleMouseout = debounce(this.handleMouseout, 250);
  },
  methods: {
    handleClick(item) {
      // Toggle selection
      item.isSelected = !item.isSelected;
      // Index of the element toggled
      const i = this.items.findIndex(d => d.id === item.id);

      // Add new reactive property (array)
      this.$set(this.items, i, {
        ...this.items[i],
        isSelected: item.isSelected
      });

      // Emit event
      this.$emit("onClick", item, item.isSelected);
    },
    handleMouseenter(item) {
      this.$emit("onMouseenter", item);
    },
    handleMouseout(item) {
      this.$emit("onMouseout", item);
    },
    handleCloseClick(item) {
      this.$emit("onCloseClick", item);
    }
  }
};
</script>

<style>
.legend {
  margin: 8px 0;
  display: flex;
  flex-wrap: wrap;
}

.legend-item {
  display: flex;
  padding: 8px;
  cursor: pointer;
  align-items: center;
  border: 1px solid transparent;
  font-size: 16px;
  max-width: 100%;
  display: inline-flex;
  justify-content: center;
  min-width: 0;
  margin-bottom: .5rem;
  max-width: 100px;
}

.legend-item:not(:last-child) {
  margin-right: 8px;
}

.legend--vertical .legend-item {
  display: flex;
  justify-content: flex-start;
  margin-left: 0;
}

.legend-item--selected,
.legend-item:hover {
  background-color: rgba(1, 144, 158, 0.1);
  border: 1px solid #d8d8d8;
  border-radius: 4px;
}

.legend-item--selected .close,
.legend-item:hover .close {
  opacity: 1;
}

.legend-item > *:not(:first-child) {
  margin-left: 8px;
}

.legend--vertical .legend-item .close {
  margin-left: auto;
}

.legend-item > *:not(.close) {
  pointer-events: none;
}

.legend-item .circle {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  opacity: 0.7;
  flex: 0 0 15px;
}

.legend-item .name {
  padding-right: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.close svg {
  pointer-events: none;
}

.legend-item .close {
  background-color: rgba(1, 144, 158, 0.3);
  border-radius: 2px;
  width: 18px;
  height: 18px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  flex: 0 0 18px;
}
</style>
