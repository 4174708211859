<template>
  <div>
    <a
      :href="sourceLink"
      class="source-data"
    >
      {{ sourceData }}
    </a>
  </div>
</template>
<script>
export default {
  name: "SourceData",
  props: {
    language: {
      type: String,
      default: ""
    },
    config: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      sourceData: '',
      sourceLink: ''
    }
  },
  created() {
    this.$root.$on('emitSourceValues', this.handleUpdataSource)
    this.$root.$on('emitSourceLinkValues', this.handleUpdateSourceLink)
  },
  mounted() {
    this.translateTitle()
    this.$root.$on('emitSourceValues', this.updateSourceLink)
    this.sourceLink = this.config.charts[0].sourceLink ? this.config.charts[0].sourceLink : ''
  },
  methods: {
    updateSourceLink(value) {
      this.sourceData = value ? value : document.documentElement.lang === 'es' ? 'Agrega un título a la fuente de datos' : 'Add a title to the data source'
    },
    translateTitle() {
      this.sourceData = this.config.charts[0].source ? this.config.charts[0].source : document.documentElement.lang === 'es' ? 'Agrega un título a la fuente de datos' : 'Add a title to the data source'
    },
    handleUpdataSource(value) {
      this.sourceData = value
    },
    handleUpdateSourceLink(value) {
      this.sourceLink = value
    },
  }
};
</script>
<style>
.source-data {
  font-size: 12px;
  color: rgba(51, 51, 51, .5);
  margin: 1rem 0 2rem 0;
  display: block;
}
</style>
