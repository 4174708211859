<template>
  <div class="action__modal">
    <div>
      <h4 class="action__title">{{ $t('options') }}</h4>
      <span v-show="changeCode" class="action__modal__message_update-code">{{ $t('changeCode') }}</span>
      <div v-if="showScales" class="action__header">
        <div class="action__header-element">
          <span class="action__header-element-title">{{ $t('scale') }}: </span>
          <button
            @click="handleLineal(); activate(1); closeModal()"
            :class="{ activeButton : activeButton === 1 }"

            class="action__header-element-button">
            {{ $t('linear') }}
          </button>
          <button
            @click="handleLog(); activate(2); closeModal()"
            :class="{ activeButton : activeButton === 2, disableButton: logNegative === true }"
            :disabled="logNegative"
            class="action__header-element-button">
            Log 10
          </button>
          <button
            @click="handleLogNatural(); activate(3); closeModal()"
            :class="{ activeButton : activeButton === 3, disableButton: logNegative === true }"
            :disabled="logNegative"
            class="action__header-element-button">
            Log e
          </button>
        </div>
        <div class="action__header-element">
          <span class="action__header-element-title">{{ $t('see') }}: </span>
          <button
            @click="handleStack(); activateChart(1); closeModal()"
            :class="{ activeButton : activeButtonChart === 1 }"
            class="action__header-element-button">{{ $t('stacked') }}</button>
          <button
            @click="handleLines(); activateChart(2); closeModal()"
            :class="{ activeButton : activeButtonChart === 2 }"
            class="action__header-element-button">{{ $t('line') }}</button>
        </div>
      </div>
      <div class="action__middle">
        <div class="action__middle-element">
          <span class="action__middle-element-title">{{ $t('language') }}:</span>
          <a
            class="action__header-element-button"
            :class="{ activeButton : this.$i18n.locale === 'es' }"
            @click="handleLocale('es')"
          >
            <span>ES</span>
          </a>
          <a
            class="action__header-element-button"
            :class="{ activeButton : this.$i18n.locale === 'en' }"
            @click="handleLocale('en')"
          >
            <span>EN</span>
          </a>
        </div>
        <div class="action__middle-element">
          <span class="action__middle-element-title">{{ $t('title') }}:</span>
          <textarea class="action__middle-element-text"
            v-model="titleGraph"
            v-on:blur="sendTextInput"
            :placeholder="$t('editTitle')"
            @focus="$event.target.select()"
            rows="1">
          </textarea>
        </div>
        <div class="action__middle-element">
          <span class="action__middle-element-title textarea-description">{{ $t('description') }}:</span>
          <textarea id="textarea-description" class="action__middle-element-text textarea-description"
            v-model="description"
            v-on:blur="sendTextInput"
            @focus="$event.target.select(); textAreaGrow()"
            rows="1">
          </textarea>
        </div>
        <div class="action__middle-element">
          <span class="action__middle-element-title">{{ $t('source') }}:</span>
          <textarea class="action__middle-element-text"
            v-model="source"
            v-on:blur="sendTextInput"
            :placeholder="this.$t('placeholderSource')"
            @focus="$event.target.select()"
            rows="1">
          </textarea>
        </div>
        <div class="action__middle-element">
          <span class="action__middle-element-title">{{ $t('sourceLink') }}:</span>
            <textarea class="action__middle-element-text"
              v-model="sourceLink"
              v-on:blur="sendTextInput"
              :placeholder="this.$t('placeholderSourceLink')"
              @focus="$event.target.select()"
              rows="1">
            </textarea>
            <a
              class="action__form-button-copy button-href-source"
              :href="this.sourceLink">
              {{ $t('buttonSource') }}
            </a>
        </div>
        <h4 class="action__title">{{ $t('codeEmbed') }}</h4>
        <button class="action__form-button-copy" @click.prevent="copy('textarea')">
          <transition-group name="copy-fade">
            <template v-if="!inputTextareaCopied">
              <v-icon :key="'icon-copy'" name="clone" scale="0.75"></v-icon>
              <span :key="'icon-text-copy'" class="action__form-button-text">{{ $t('buttonCopy') }}</span>
            </template>
            <template v-else>
              <v-icon :key="'icon-copied'" name="check" scale="0.75"></v-icon>
              <span :key="'icon-text-copied'" class="action__form-button-text">{{ $t('buttonCopied') }}</span>
            </template>
          </transition-group>
        </button>
        <form class="action__form">
          <textarea v-html="textCode" @focus="$event.target.select()" class="embed-code" rows="5" id="action__form-textarea"></textarea>
        </form>
      </div>
    </div>
    <div class="action__download">
      <div>
        <strong>{{ $t('download') }}:</strong>
        <span @click="toImg('png')">PNG</span>
        <span @click="toImg('svg')">SVG</span>
      </div>
    </div>
    <span
      class="action__close"
      @click.prevent="closeModal"
    >
      <v-icon
        name="times"
        scale="0.75" />
    </span>
  </div>
</template>
<i18n src="../locales/actionModal.json"></i18n>
<script>
/* eslint-disable no-useless-escape */
import "vue-awesome/icons/times";
import "vue-awesome/icons/clone";
import "vue-awesome/icons/check";
import Icon from "vue-awesome/components/Icon";

export default {
  components: {
    "v-icon": Icon
  },
  props: {
    id: {
      type: String,
      default: ""
    },
    shareUrl: {
      type: String,
      default: ""
    },
    exportMarkup: HTMLDivElement,
    exportData: {
      type: Array,
      default: () => []
    },
    exportDataFilter: {
      type: Array,
      default: () => []
    },
    titleDataset: {
      type: String,
      default: ""
    },
    sourceDataset: {
      type: String,
      default: ""
    },
    config: {
      type: Object,
      default: () => {}
    },
    language: {
      type: String,
      default: ""
    }
  },
  watch: {
    language(newValue) {
      this.selectedLanguage = `,
    language: ${newValue}`
      this.mapProperty = this.config.charts[0].mapProperty
      this.mapPropertyInverted = this.config.charts[0].mapPropertyInverted
      this.titleLegend = this.config.charts[0].titleLegend
      this.sendTextInput()
    }
  },
  data() {
    return {
      inputLinkCopied: false,
      inputTextareaCopied: false,
      textCode: null,
      valueVisualizer: null,
      isActive: false,
      nameValuesDispersion: Array,
      urlConnector: null,
      textButtonCopyInput: this.$t('buttonCopy'),
      textButtonCopyTextArea: this.$t('buttonCopy'),
      arrayValuesBrush: Array,
      minDefaultValue: null,
      maxDefaultValue: null,
      valueIdWebComponent: null,
      changeCode: false,
      activeButton: 1,
      activeButtonChart: 2,
      titleGraph: '',
      typeChart: '',
      description: '',
      source: '',
      sourceLink: '',
      initChart: 0,
      yScaleValueString: null,
      groupValues: '',
      logNegative: false,
      idvaluesX: '',
      selectedLanguage: ''
    };
  },
  created() {
    this.$i18n.locale = document.documentElement.lang
    this.yScaleFunctionString = this.config.charts[0].yScaleFunction === undefined ? "d3 => d3.scaleLinear()" : this.config.charts[0].yScaleFunction
    this.yScaleFunctionString = this.yScaleFunctionString.toString()

    if(this.yScaleFunctionString.includes('d3.scaleLinear')) {
      this.activeButton = 1
      this.yScaleValueString = "yScaleFunction: d3 => d3.scaleLinear()"
    } else if (this.yScaleFunctionString.includes('d3.scaleLog().base(Math.E)')) {
      this.activeButton = 3
      this.yScaleValueString = 'yScaleFunction: d3 => d3.scaleLog().base(Math.E)'
    } else if (this.yScaleFunctionString.includes('d3.scaleLog()')) {
      this.activeButton = 2
      this.yScaleValueString = "yScaleFunction: d3 => d3.scaleLog()"
    }

    this.activeButtonChart = this.config.charts[0].type === "Line" ? 2 : 1

    //Check if string values are empty or undefined
    if(this.config.charts[0].title === "" || this.config.charts[0].title === undefined) {
      this.titleGraph = ''
    } else {
      this.titleGraph = this.config.charts[0].title
    }

    if(this.config.charts[0].group === "" || this.config.charts[0].group === undefined || this.config.charts[0].group === null) {
      this.groupValues = this.groupValues
    } else {
      this.groupValues = JSON.stringify(this.config.charts[0].group)
    }

    if(this.config.charts[0].source === "" || this.config.charts[0].source === undefined) {
      this.source = this.source
    } else {
      this.source = this.config.charts[0].source
    }

    if(this.config.charts[0].description === "" || this.config.charts[0].description === undefined) {
      this.description = this.description
    } else {
      this.description = this.config.charts[0].description
    }

    if(this.config.charts[0].sourceLink === "" || this.config.charts[0].sourceLink === undefined) {
      this.sourceLink = this.sourceLink
    } else {
      this.sourceLink = this.config.charts[0].sourceLink
    }

    if(this.groupValues === undefined || this.groupValues === "") {
      this.groupValues = ''
    } else {
      this.groupValues = `,
    group: ${this.groupValues}`
    }

    //Extract value to embed code
    this.typeConfig = this.config.charts[0].type
    this.brushConfig = this.config.charts[0].brush
    this.defaultsConfig = this.config.charts[0].defaults || ""
    this.aspectRatioConfig = this.config.charts[0].aspectRatio
    this.hasContainer = this.config.charts[0].hasContainer
    this.showScales = this.config.charts[0].showScales === false ? false : true
    this.mapProperty = this.config.charts[0].mapProperty
    this.mapPropertyInverted = this.config.charts[0].mapPropertyInverted
    this.titleLegend = this.config.charts[0].titleLegend
    this.showCountriesHeatMap = this.config.charts[0].countries
    this.showBspline = this.config.charts[0].bspline ?
    `,
    bspline: true` : `,
    bspline: false`
    this.selectedLanguage = `,
    language: "${this.language}"`

    //Get any data update from charts for embed code.
    this.$root.$on('updateValuesVarX', this.updateEmbedValuesLine)
    this.$root.$on('updateLineValuesBrush', this.updateEmbedValuesLineBrush)
    this.$root.$on('updateDispersionRange', this.updateEmbedValuesDispersionRange)
    this.$root.$on('updateDispersionAggregation', this.updateEmbedValuesDispersionAggregation)
    this.$root.$on('updateDispersionX', this.updateEmbedValuesDispersionX)
    this.$root.$on('updateDispersionY', this.updateEmbedValuesDispersionY)
    this.$root.$on('updateDispersionRange', this.updateEmbedValuesDispersionRange)
    this.$root.$on('updateNegativeValues', this.updateNegativeLog)
    this.$root.$on('updateMapProperty', this.updateMapProperty)
    this.$root.$emit('emitSourceLinkValues', this.sourceLink)
    this.$root.$emit('emitSourceValues', this.source)

    this.getIdElement()
    if (this.hasContainer) {
      this.updateEmbedValuesTextAreaContainer()
    } else {
      this.updateEmbedValuesTextArea()
    }
  },
  methods: {
    handleLocale(language) {
      this.$i18n.locale = language

      this.$emit("change-language", language);
      this.closeModal()
    },
    sendTextInput(){
      this.updateEmbedValuesTextArea()
      this.updateEmbedValuesTextAreaContainer()
      this.$root.$emit('emitTitleValues', this.titleGraph)
      this.$root.$emit('emitDescriptionValues', this.description)
      this.$root.$emit('emitSourceValues', this.source)
      this.$root.$emit('emitSourceLinkValues', this.sourceLink)
    },
    updateNegativeLog(value) {
      this.logNegative = value
    },
    updateMapProperty(value) {
      const { id, name } = value
      this.mapProperty = id
      this.titleLegend = name
      this.updateEmbedValuesTextAreaContainer()
    },
    textAreaGrow() {
      const tx = document.getElementById('textarea-description');
      for (var i = 0; i < tx.length; i++) {
        tx[i].setAttribute('style', 'height:' + (tx[i].scrollHeight - 16) + 'px;overflow-y:hidden;');
        tx[i].addEventListener("input", OnInput, false);
      }

      function OnInput() {
        this.style.height = 'auto';
        this.style.height = (this.scrollHeight - 16) + 'px';
      }

    },
    handleLog() {
      this.$root.$emit("changeScaleLog");
      this.yScaleValueString = "yScaleFunction: d3 => d3.scaleLog()"
      if (this.hasContainer) {
        this.updateEmbedValuesTextAreaContainer()
      } else {
        this.updateEmbedValuesTextArea()
      }
    },
    handleLogNatural() {
      this.$root.$emit("changeScaleLogNatural");
      this.yScaleValueString = "yScaleFunction: d3 => d3.scaleLog().base(Math.E)"
      if (this.hasContainer) {
        this.updateEmbedValuesTextAreaContainer()
      } else {
        this.updateEmbedValuesTextArea()
      }
    },
    handleLineal() {
      this.$root.$emit("changeScaleLineal");
      this.yScaleValueString = "yScaleFunction: d3 => d3.scaleLinear()"
      if (this.hasContainer) {
        this.updateEmbedValuesTextAreaContainer()
      } else {
        this.updateEmbedValuesTextArea()
      }
    },
    handleStack() {
      this.$root.$emit("changeChartStack", true);
      if (this.hasContainer) {
        this.config.charts[0].type = "MapContainer"
        this.config.charts[0].typeChart = "Stacked"
        this.updateEmbedValuesTextAreaContainer()
      } else {
        this.config.charts[0].type = "Stacked"
        this.typeConfig = "Stacked"
        this.updateEmbedValuesTextArea()
      }
    },
    handleLines() {
      this.$root.$emit("changeChartLine", false);
      if (this.hasContainer) {
        this.config.charts[0].type = "MapContainer"
        this.config.charts[0].typeChart = "Line"
        this.updateEmbedValuesTextAreaContainer()
      } else {
        this.config.charts[0].type = "Line"
        this.typeConfig = "Line"
        this.updateEmbedValuesTextArea()
      }
    },
    activate(el){
      this.activeButton = el;
    },
    activateChart(el){
      this.activeButtonChart = el;
    },
    closeModal() {
      this.$emit("close-modal");
      this.isActive = true
      this.changeCode = false
    },
    getIdElement() {
      const divs = document.getElementsByTagName("populate-charts");
      let item
      for (let i = 0; i < divs.length; i++) {
          item = divs[i];
          if (item.id && item.id.indexOf("visualizer-") === 0) {
              this.valueIdWebComponent = item.id
          }
      }
    },
    getUrlConnector() {
      const currentUrl = window.location.hostname;
      const developUrl = "localhost"
      /*What connector does the user use? Regex to find URL in HTML.*/
      const regexConnector = /<script.*?"(https?:\/\/data-visualizer.*?)"/;
      const nodeBody = document.querySelector('html')
      const textBody = nodeBody.outerHTML
      const stringBody = textBody.toString()
      this.urlConnector = currentUrl === developUrl ? "localhost" : stringBody.match(regexConnector)[0]
      if(this.urlConnector === null) {
        const regexConnector = /<script.*?"(https?:\/\/data-visualizer.*?)"/;
        const nodeBody = document.querySelector('head')
        const textBody = nodeBody.outerHTML
        const stringBody = textBody.toString()
        this.urlConnector = currentUrl === developUrl ? "localhost" : stringBody.match(regexConnector)[0]
      }
    },
    updateEmbedValuesDispersionRange(valueRange) {
      this.changeCode = true
      this.getUrlConnector()
      const getValueRange = valueRange
      this.defaultDispersionRange = getValueRange
      this.aspectRatioValue = this.aspectRatioConfig !== undefined ? `,aspectRatio: ${this.aspectRatioConfig}` : ''

      if (this.typeConfig === "Dispersion") {
        this.valueVisualizer = `new Visualizer([
  {
    type: "${this.typeConfig}",
    defaults: {
      x: ${this.defaultDispersionX},
      y: ${this.defaultDispersionY},
      range: ${this.defaultDispersionRange},
      aggregation: ${this.defaultsConfigValues}
    }${this.aspectRatioValue}
  }
]);`
      }
      this.textCode = `${this.urlConnector}><\/script>
<script src="https://data-visualizer.populate.tools/dist/populate-charts.js"><\/script>
<populate-charts id="${this.valueIdWebComponent}"><\/populate-charts>
<script>
document.getElementById("${this.valueIdWebComponent}").config =
${this.valueVisualizer}
<\/script>`
    },
    updateEmbedValuesTextArea() {
      this.changeCode = this.initChart ? true : false
      this.getUrlConnector()

        this.aspectRatioValue = this.aspectRatioConfig !== undefined ? `,
    aspectRatio: ${this.aspectRatioConfig}` : ''

      if(this.titleGraph === undefined) {
        this.titleGraphValue = ''
      } else if(this.titleGraph === "") {
        this.titleGraphValue =`
    title: "",`
      } else {
        this.titleGraphValue = `
    title: "${this.titleGraph}",`
      }

      if(this.description === undefined) {
        this.descriptionValue = ''
      } else if(this.description === "") {
        this.descriptionValue = `
    description: "",`
      } else {
        this.descriptionValue = `
    description: "${this.description}",`
      }

      if(this.source === undefined) {
        this.titleSourceValue = ''
      } else if(this.source === "") {
        this.titleSourceValue = `
    source: "",`
      } else {
        this.titleSourceValue = `
    source: "${this.source}",`
      }

      if(this.sourceLink === undefined) {
        this.sourceLinkValue = ''
      } else if(this.sourceLink === "") {
        this.sourceLinkValue = `
    sourceLink: "",`
      } else {
        this.sourceLinkValue = `
    sourceLink: "${this.sourceLink}",`
      }

      this.yScaleValueString = this.yScaleValueString !== undefined ? `${this.yScaleValueString}` : ''

      if (this.typeConfig === "Line" || this.typeConfig === "Stacked") {
        this.valueVisualizer = `new Visualizer([
  {
    type: "${this.typeConfig}",
    defaults: {
      x: ${this.defaultsConfigValues},
    },
    brush: ${this.arrayValuesBrush}${this.aspectRatioValue},${this.titleGraphValue}${this.descriptionValue}${this.titleSourceValue}${this.sourceLinkValue}
    ${this.yScaleValueString}${this.groupValues}${this.showBspline}${this.selectedLanguage}
  }
]);`
      }
      this.textCode = `${this.urlConnector}><\/script>
<script src="https://data-visualizer.populate.tools/dist/populate-charts.js"><\/script>
<populate-charts id="${this.valueIdWebComponent}"><\/populate-charts>
<script>
document.getElementById("${this.valueIdWebComponent}").config =
${this.valueVisualizer}
<\/script>`

    },
    updateEmbedValuesDispersionY(valueY) {
      this.changeCode = this.initChart ? true : false
      this.getUrlConnector()
      this.aspectRatioValue = this.aspectRatioConfig !== undefined ? `,
    aspectRatio: ${this.aspectRatioConfig}` : ''
      const getValueY = valueY.id
      this.defaultDispersionY = getValueY
      if (this.typeConfig === "Dispersion") {
        this.valueVisualizer = `new Visualizer([
  {
    type: "${this.typeConfig}",
    defaults: {
      x: ${this.defaultDispersionX},
      y: ${this.defaultDispersionY},
      range: ${this.defaultDispersionRange},
      aggregation: ${this.defaultsConfigValues}
    }${this.aspectRatioValue}
  }
]);`
      }
      this.textCode = `${this.urlConnector}><\/script>
<script src="https://data-visualizer.populate.tools/dist/populate-charts.js"><\/script>
<populate-charts id="${this.valueIdWebComponent}"><\/populate-charts>
<script>
document.getElementById("${this.valueIdWebComponent}").config =
${this.valueVisualizer}
<\/script>`
    },
    updateEmbedValuesDispersionX(valueX) {
      this.changeCode = this.initChart ? true : false
      const getValueX = valueX.id
      this.defaultDispersionX = getValueX
      this.getUrlConnector()
      this.aspectRatioValue = this.aspectRatioConfig !== undefined ? `,
    aspectRatio: ${this.aspectRatioConfig}` : ''

      if (this.typeConfig === "Dispersion") {
        this.valueVisualizer = `new Visualizer([
  {
    type: "${this.typeConfig}",
    defaults: {
      x: ${this.defaultDispersionX},
      y: ${this.defaultDispersionY},
      range: ${this.defaultDispersionRange},
      aggregation: ${this.defaultsConfigValues}
    }${this.aspectRatioValue}

  }
]);`
      }

      this.textCode = `${this.urlConnector}><\/script>
<script src="https://data-visualizer.populate.tools/dist/populate-charts.js"><\/script>
<populate-charts id="${this.valueIdWebComponent}"><\/populate-charts>
<script>
document.getElementById("${this.valueIdWebComponent}").config =
${this.valueVisualizer}
<\/script>
`
    },
    updateEmbedValuesDispersionAggregation(valuesDispersion) {
      this.changeCode = this.initChart ? true : false
      this.getUrlConnector()
      this.aspectRatioValue = this.aspectRatioConfig !== undefined ? `,
    aspectRatio: ${this.aspectRatioConfig}` : ''
      this.nameValuesDispersion = valuesDispersion.map(d => d.name);
      if (this.typeConfig === "Dispersion") {
        this.defaultsConfigValues = JSON.stringify(this.nameValuesDispersion)
        this.valueVisualizer = `new Visualizer([
  {
    type: "${this.typeConfig}",
    defaults: {
      x: ${this.defaultDispersionX},
      y: ${this.defaultDispersionY},
      range: ${this.defaultDispersionRange},
      aggregation: ${this.defaultsConfigValues}
    }${this.aspectRatioValue}
  }
]);`
      }
      this.textCode = `${this.urlConnector}><\/script>
<script src="https://data-visualizer.populate.tools/dist/populate-charts.js"><\/script>
<populate-charts id="${this.valueIdWebComponent}"><\/populate-charts>
<script>
document.getElementById("${this.valueIdWebComponent}").config =
${this.valueVisualizer}
<\/script>`
    },
    updateEmbedValuesLine(valuesX) {
      this.idvaluesX = valuesX.map(d => d.id);
      if (this.hasContainer) {
        this.updateEmbedValuesTextAreaContainer()
        return
      }

      this.changeCode = this.initChart ? true : false
      this.getUrlConnector()

      this.aspectRatioValue = this.aspectRatioConfig !== undefined ? `,
    aspectRatio: ${this.aspectRatioConfig}` : ''

      if (this.typeConfig === "Line" || this.typeConfig === "Stacked") {
        this.defaultsConfigValues = JSON.stringify(this.idvaluesX)
        this.valueVisualizer = `new Visualizer([
  {
    type: "${this.typeConfig}",
    defaults: {
      x: ${this.defaultsConfigValues},
    },
    brush: ${this.arrayValuesBrush}${this.aspectRatioValue},${this.titleGraphValue}${this.descriptionValue}${this.titleSourceValue}${this.sourceLinkValue}
    ${this.yScaleValueString}${this.selectedLanguage},${this.groupValues}${this.showBspline}
  }
]);`
      }
      this.textCode = `${this.urlConnector}><\/script>
<script src="https://data-visualizer.populate.tools/dist/populate-charts.js"><\/script>
<populate-charts id="${this.valueIdWebComponent}"><\/populate-charts>
<script>
document.getElementById("${this.valueIdWebComponent}").config =
${this.valueVisualizer}
<\/script>`

    },
    updateEmbedValuesLineBrush(valuesBrush) {
      this.arrayValuesBrush = [valuesBrush[0].getFullYear(), valuesBrush[1].getFullYear()]
      if (this.hasContainer) {
        this.updateEmbedValuesTextAreaContainer()
      } else {

      this.arrayValuesBrush = JSON.stringify(this.arrayValuesBrush)
      if(this.initChart < 2) {
        this.changeCode = false
      } else {
        this.changeCode = true
      }

      this.getUrlConnector()
      this.aspectRatioValue = this.aspectRatioConfig !== undefined ? `,
    aspectRatio: ${this.aspectRatioConfig}` : ''

      this.initChart++


      if (this.typeConfig === "Line" || this.typeConfig === "Stacked") {
        this.valueVisualizer = `new Visualizer([
  {
    type: "${this.typeConfig}",
    defaults: {
      x: ${this.defaultsConfigValues},
    },
    brush: ${this.arrayValuesBrush}${this.aspectRatioValue},${this.titleGraphValue}${this.descriptionValue}${this.titleSourceValue}${this.sourceLinkValue}
    ${this.yScaleValueString}${this.groupValues}${this.selectedLanguage}${this.showBspline}
  }
]);`
      }
      this.textCode = `${this.urlConnector}><\/script>
<script src="https://data-visualizer.populate.tools/dist/populate-charts.js"><\/script>
<populate-charts id="${this.valueIdWebComponent}"><\/populate-charts>
<script>
document.getElementById("${this.valueIdWebComponent}").config =
${this.valueVisualizer}
<\/script>`
}
    },
    updateEmbedValuesTextAreaContainer() {
      this.defaultsConfigValues = this.idvaluesX === '' ? JSON.stringify(this.defaultsConfig.x) : JSON.stringify(this.idvaluesX)
      this.arrayValuesBrush = typeof this.arrayValuesBrush === 'function' ? this.brushConfig : this.arrayValuesBrush

    if(this.titleGraph === undefined) {
      this.titleGraphValue = ''
    } else if(this.titleGraph === "") {
      this.titleGraphValue =`
  title: "",`
    } else {
      this.titleGraphValue = `
  title: "${this.titleGraph}",`
    }

    if(this.description === undefined) {
      this.descriptionValue = ''
    } else if(this.description === "") {
      this.descriptionValue = `
  description: "",`
    } else {
      this.descriptionValue = `
  description: "${this.description}",`
    }

    if(this.source === undefined) {
      this.titleSourceValue = ''
    } else if(this.source === "") {
      this.titleSourceValue = `
  source: "",`
    } else {
      this.titleSourceValue = `
  source: "${this.source}",`
    }

    if(this.sourceLink === undefined) {
      this.sourceLinkValue = ''
    } else if(this.sourceLink === "") {
      this.sourceLinkValue = `
  sourceLink: ""`
    } else {
      this.sourceLinkValue = `
  sourceLink: "${this.sourceLink}"`
    }
  this.selectedLanguage = `,
  language: "${this.language}"`

      this.getUrlConnector()
      this.aspectRatioValue = this.aspectRatioConfig !== undefined ? `,
    aspectRatio: ${this.aspectRatioConfig}` : ''
    if(this.typeConfig === "Map") {
      this.valueVisualizer = `new Visualizer([
{
  type: "${this.typeConfig}",
  defaults: {
    x: ${this.defaultsConfigValues},
  },
  brush: [${this.arrayValuesBrush}]${this.aspectRatioValue},${this.titleGraphValue}${this.descriptionValue}${this.titleSourceValue}${this.sourceLinkValue}${this.selectedLanguage},
  mapProperty: "${this.mapProperty}",
  titleLegend: "${this.titleLegend}"
}
]);`
}

if(this.typeConfig === "HeatMap") {
      this.valueVisualizer = `new Visualizer([
{
  type: "${this.typeConfig}",
  countries: "${this.showCountriesHeatMap}",${this.titleGraphValue}${this.descriptionValue}${this.titleSourceValue}${this.sourceLinkValue}${this.selectedLanguage}
}
]);`
}

      this.textCode = `${this.urlConnector}><\/script>
<script src="https://data-visualizer.populate.tools/dist/populate-charts.js"><\/script>
<populate-charts id="${this.valueIdWebComponent}"><\/populate-charts>
<script>
document.getElementById("${this.valueIdWebComponent}").config =
${this.valueVisualizer}
<\/script>`
    },
    copy(id) {
      document.getElementById(`action__form-${id}`).select();
      document.execCommand("copy");

      this.inputLinkCopied = id === "input";
      this.inputTextareaCopied = id === "textarea";


      if (this.inputLinkCopied === true) {
        this.textButtonCopyInput = "Copiado"

      } else if (this.inputTextareaCopied === true) {
        this.textButtonCopyTextArea = "Copiado"
      }

      setTimeout(() => {
        this.textButtonCopyTextArea = "Copiar"
        this.textButtonCopyInput = "Copiar"
        this.inputLinkCopied = false;
        this.inputTextareaCopied = false;
      }, 3000);
    },
    async toImg(type) {
      const domtoimage = await import("dom-to-image");
      const lib = await import("file-saver");
      const saveAs = lib.saveAs;

      const graphMaxWidth = 1440;

      const containerLegends = document.createElement('div')
      containerLegends.setAttribute('id', 'image-legends')

      const source = document.createElement('p')
      source.setAttribute('class', 'text-graph')
      source.setAttribute('id', 'source-legends')
      source.style.maxWidth = `${graphMaxWidth}px`;

      const contentText = document.createTextNode(this.source)
      source.appendChild(contentText);
      this.exportMarkup.appendChild(source);

      this.exportMarkup.setAttribute("style", "font-family: sans-serif;");

      const title = document.createElement('h2')
      title.setAttribute('class', 'title-graph')
      title.style.maxWidth = `${graphMaxWidth}px`;
      const content = document.createTextNode(this.titleGraph)
      title.appendChild(content);
      containerLegends.insertBefore(title, containerLegends.firstChild);

      this.exportMarkup.insertBefore(containerLegends, this.exportMarkup.firstChild)


      //Filter SVG-ICONS
      function filter(node) {
        return (node.id !== 'graph-brush' && node.id !== 'btn-reset-brush' && node.id !== 'line-selector-right');
      }

      if (type === "png") {
        const blob = await domtoimage.toBlob(this.exportMarkup, { filter: filter, bgcolor: '#fff' });
        saveAs(blob, `${this.id}.png`);
        this.exportMarkup.removeChild(this.exportMarkup.firstChild);
        this.exportMarkup.removeChild(this.exportMarkup.lastChild);
        this.exportMarkup.setAttribute('style', '');

      } else if (type === "svg") {
        const dataUrl = await domtoimage.toSvg(
          this.exportMarkup, { filter: filter }
        );
        saveAs(dataUrl, `${this.id}.svg`);

        this.exportMarkup.removeChild(this.exportMarkup.firstChild);
        this.exportMarkup.removeChild(this.exportMarkup.lastChild);
        this.exportMarkup.setAttribute('style', '');
      }
    },
    async toCsv(filter = false) {
      const lib = await import("papaparse");
      const Papa = lib.default;
      const lib0 = await import("downloadjs");
      const download = lib0.default;
      // const lib0 = await import("file-saver");
      // const saveAs = lib0.saveAs;

      const data = filter ? this.exportDataFilter : this.exportData;
      download(Papa.unparse(data), `${this.id}.csv`);
      // saveAs(Papa.unparse(data), `${this.id}.csv`);
    }
  }
};
</script>
<style>
.action__modal {
  --gap: 25px;
  background-color: #fff;
  border: 1px solid #cacaca;
  border-radius: 4px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.15);
  color: #4a4a4a;
  position: absolute;
  padding: 25px;
  padding: var(--gap, 25px);
  right: -25px;
  right: calc(-1 * var(--gap, 25px));
  top: -25px;
  top: calc(-1 * var(--gap, 25px));
  z-index: 1;
  cursor: auto;
  width: 70vw;
  visibility: hidden;
  opacity: 0;
  transition: all .4s ease-in-out;
  transition-property: opacity, visibility;
  font-family: 'Libre Franklin', sans-serif;
}

.action__modal>div:not(:first-of-type) {
  padding-top: 15px;
  border-top: 1px solid rgba(151, 151, 151, .2);
}

.action__modal>div:not(:last-of-type) {
  padding-bottom: 15px;
}

.action__title--line-height {
  line-height: 28px;
}

.action__title {
  font-size: 16px;
  text-align: left;
  font-weight: 700;
  margin-top: 0;
  display: inline-block;
  width: 100%;
  text-align: left;
}

.action__middle .action__title {
  width: calc(100% - 120px);
  padding-top: 2.5rem;
}

.action__close {
  background-color: #01909e;
  opacity: .5;
  height: 28px;
  width: 28px;
  border-radius: 100%;
  display: inline-flex;
  color: #fff;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  top: 25px;
  top: var(--gap, 25px);
  right: 25px;
  right: var(--gap, 25px);
}

.action__checkbox {
  display: block;
  font-size: 14px;
}

.action__checkbox:last-of-type {
  margin-bottom: 15px;
}

.action__form {
  --input-padding: 4px;
  background-color: #f6f6f6;
  border: 1px solid #cacaca;
  border-radius: 4px;
  padding: 4px;
  padding: var(--input-padding, 4px);
  position: relative;
  font-size: 100%;
  line-height: 1;
}

.action__form-copied {
  position: absolute;
  right: 90px;
  background-color: #f6f6f6;
  color: #01909e;
  top: 50%;
  transform: translateY(-50%);
  font-size: 13px;
  padding: 5px;
}

.action__form-copied--textarea {
  top: 80%;
  transform: translateY(-40%);
}

.action__form-button-copy {
  background-color: #01909e;
  border-radius: 4px;
  min-width: 120px;
  border: 0;
  cursor: pointer;
  padding: .5rem 1.5rem;
  display: inline-block;
  margin-left: auto;
  margin-top: .25rem;
  transition: background-color .3s ease-in;
}

.action__form-button-copy svg {
  color: #fff;
  display: inline-block;
}

.action__form-button-text {
  color: #fff;
  font-size: 13px;
  font-weight: 700;
  margin-left: .25rem;
  display: inline-block;
}

.action__form-button-copy:hover,
.hoverButtton {
  background-color: #00636d;
}

.action__form-button-copy:first-child {
  margin-right: 8px;
}

.action__form input {
  border: 0;
  flex: 1;
  background: none;
  color: #4a4a4a;
  font-size: 13px;
  font-weight: 400;
  line-height: 28px;
  font-family: inherit;
  padding: 0;
  width: 100%;
}

.action__form input:focus {
  outline: none;
}

.action__form textarea {
  border: none;
  overflow: auto;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  resize: none;
  background: none;
  font-family: inherit;
  font-size: 13px;
  width: 100%;
}

.action__download {
  display: none;
  font-size: 15px;
  line-height: 18px;
  justify-content: space-between;
}

.action__download> :last-child {
  text-align: right;
}

.action__download span,
.action__languages span {
  margin-left: 20px;
  text-decoration: underline;
  color: #01909e;
  cursor: pointer;
  font-size: 15px;
}

.action__languages span {
  margin-left: 0;
}

.copy-fade-enter-active,
.copy-fade-leave-active {
  transition: opacity 0.5s ease;
}

.copy-fade-enter,
.copy-fade-leave-to {
  opacity: 0;
}

@media screen and (min-width: 768px) {
  .action__modal {
    width: 760px;
  }

  .action__download {
    display: flex;
  }
}

.embed-code {
  white-space: pre-wrap;
  word-break: break-all;
  display: inline;
  margin: 0;
}

.embed-code#action__form-textarea {
  font-family: monospace;
  font-size: 12px;
  height: 200px !important;
  overflow-y: scroll !important;
}

.action__header {
  font-size: Arial, 'sans-serif';
}

.action__header-element {
  width: 50%;
  display: inline-block;
  text-align: left;
}

.action__header-element-title {
  font-size: 14px;
  color: #4A494A;
  display: inline-block;
  padding-right: .5rem;
}

.action__header-element-button {
  border-radius: 3px;
  border: 1px solid #87A8AD;
  color: #000;
  padding: .25rem;
  font-size: 12px;
  text-align: center;
  font-weight: 600;
  margin-right: .25rem;
  cursor: pointer;
  text-decoration: none;
}

.action__header-element-button:hover {
  background-color: rgba(1, 144, 158, .6);
  color: #fff;
  font-weight: 700;
}

.action__header-input-text {
  font-size: 12px;
  border-radius: 3px;
  background-color: #F6F6F6;
  border: 1px solid #cacaca;
  padding: .25rem;
  width: 30px;
  margin-left: .5rem;
}

.action__middle {
  margin-top: 1rem;
  text-align: left;
  box-sizing: border-box;
  font-size: 0;
}

.action__middle-element {
  margin-bottom: .5rem;
  position: relative;
}

.action__middle-element-title {
  width: 15%;
  font-size: 12px;
  display: inline-block;
  vertical-align: top;
  padding-top: .5rem;
}

.action__middle-element-text {
  width: 82%;
  display: inline-block;
  font-size: 13px;
  background-color: #F6F6F6;
  border: 1px solid #CACACA;
  border-radius: 4px;
  padding: .5rem;
  min-height: 18px;
  resize: none;
  box-sizing: content-box;
  transition: min-height .3s ease-in;
}

.action__modal__message_update-code {
  color: #4A494A;
  font-size: 13px;
  font-weight: 400;
  text-align: center;
  background-color: #FFEBBD;
  border-radius: 5px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.4);
  padding: .5rem;
  position: absolute;
  top: 1rem;
  left: 7rem;
}

.activeButton,
#activeButton {
  background-color: #01909E;
  color: #fff;
  font-weight: 700;
}

.button-href-source {
  font-size: 12px;
  position: absolute;
  right: .5rem;
  padding: .5rem 0;
  color: #fff;
  text-align: center;
  text-decoration: none;
  top: -1px;
}

.disableButton {
  display: none;
}

@media only screen and (max-width: 525px) {
  .button-href-source {
    position: initial;
  }

  .action__middle-element-title,
  .action__header-element,
  .action__middle-element-text {
    width: 100%;
    box-sizing: border-box;
  }

  .action__header-element {
    margin-bottom: .75rem;
  }

  .action__header-element-title {
    display: inline-block;
    width: 15%;
  }

  .action__header-element-button {
    display: inline-block;
  }

  .action__middle {
    margin-top: .25rem;
  }
}

</style>
