<template>
  <div class="container-brush">
    <button
      v-if="selectionBrush"
      @click="resetBrush"
      class="reset-brush"
      id="btn-reset-brush"
    >
     <svg class="reset-brush-icon" width="28" height="28" xmlns="http://www.w3.org/2000/svg"><circle stroke="#FFF" fill="#E5E5E5" fill-opacity="0.8" cx="14" cy="14" r="14" stroke-width="1.5"/>
      <path d="M15.494 13.106H9.807a.342.342 0 01-.341-.342v-.227c0-.188.153-.341.34-.341h5.688c.188 0 .342.153.342.34v.228a.342.342 0 01-.342.342zm5.702 7.769l-.321.321a.342.342 0 01-.484 0l-3.668-3.668a.338.338 0 01-.1-.242v-.242a5.89 5.89 0 01-3.967 1.527 5.902 5.902 0 01-5.915-5.838 5.916 5.916 0 015.992-5.992 5.902 5.902 0 015.838 5.915 5.89 5.89 0 01-1.527 3.967h.242a.35.35 0 01.242.1l3.668 3.668a.342.342 0 010 .484zm-3.535-8.219a5.001 5.001 0 00-5.005-5.005 5.001 5.001 0 00-5.005 5.005 5.001 5.001 0 005.005 5.005 5.001 5.001 0 005.005-5.005z" fill="#8E8E8E"/></svg>
    </button>
  </div>
</template>
<script>
import * as d3 from 'd3';

const maxWidth = 1440
const height = 40
const margin = {
  top: 10,
  right: 10,
  bottom: 10,
  left: 20
}


export default {
  name: "Brush",
  data() {
    return {
      selectionBrush: false,
      value: [],
      loadBrush: false,
      stopAnimation: false,
      xMin: null,
      xMax: null,
      xMinDefault: null,
      xMaxDefault: null,
      userResetBrush: false
    }
  },
  props: {
    data: Array,
    properties: Object,
    OnBrushed: {
      type: Function
    }
  },
  created() {
    /*Check if user introduces a range of years in config,
    if not add any we set the min and max value from
    dataset
    */
    if(this.properties.brush === undefined) {
      this.xMax = d3.max(this.data, d => d.time)
      this.xMin = d3.min(this.data, d => d.time)
    } else {
      this.xMinYear = this.properties.brush[0]
      this.xMaxYear = this.properties.brush[1]

      //Convert year in Javascript Date
      this.xMin = new Date(this.xMinYear, 0);
      this.xMax = new Date(this.xMaxYear, 0);
    }

    this.xMaxDefault = d3.max(this.data, d => d.time)
    this.xMinDefault = d3.min(this.data, d => d.time)

    this.xMinDefaultYear = this.xMinDefault.getFullYear()
    this.xMaxDefaultYear = this.xMaxDefault.getFullYear()

    if (this.xMinYear !== this.xMinDefaultYear || this.xMaxYear !== this.xMaxDefaultYear) {
      this.selectionBrush = true
    } else {
      this.selectionBrush = false
    }

  },
  mounted() {
    this.ctx = this.$parent.$refs.chart;
    window.addEventListener("resize", this.chartBrush)
    this.chartBrush()
  },
  methods: {
    chartBrush() {
      this.container = d3.select(this.ctx)
      this.width = Math.min(maxWidth, +this.container.node().getBoundingClientRect().width);


      this.container
        .selectAll('#graph-brush')
        .remove()
        .exit()

      this.svgCreate = d3.select(this.ctx)
        .append("svg")
        .attr('id', 'graph-brush')
        .attr('width', this.width - (margin.left * 2))
        .attr('height', height)
        .attr('transform', `translate(${margin.left * 1.5},0)`)

      this.xScale = d3
        .scaleTime()
        .domain([this.xMinDefault, this.xMaxDefault])
        .range([margin.left, this.width - (margin.right * 6)]);

      this.axisX = d3
        .axisBottom(this.xScale)
        .tickPadding(5)
        .tickFormat(d3.format('d'))
        .ticks(13);

      this.brush = d3.brushX().extent([
        [margin.left, margin.top],
        [this.width - (margin.right * 6), height - margin.bottom]
      ]).on('brush', this.brushEnd)

      this.container
        .selectAll('.brush')
        .remove()
        .exit()

      this.gBrush = this.svgCreate
        .append("g")
        .attr("class", "brush")
        .call(this.brush)

      this.handle = this.gBrush.selectAll(".handle--custom")
        .data([{ type: "w" }, { type: "e" }])
        .enter().append("g")
        .attr("class", "handle--custom")
        .attr("fill", "#666")
        .attr("fill-opacity", 0.8)
        .attr("stroke", "#000")
        .attr("stroke-width", 1.5)
        .attr("cursor", "ew-resize")

      this.BrushCustomGroup = this.handle.append('g')
        .attr('fill', '#E8E8E8')
        .attr('stroke', '#fff')
        .attr('transform', 'translate(0,-8)')

      this.BrushCustomGroup.append('circle')
        .attr('cx','14')
        .attr('cy','14')
        .attr('r','14')
        .attr('fill', '#E8E8E8')

      this.BrushCustomGroup2 = this.handle.append('g')
        .attr('stroke', '#8E8E8E')
        .attr('transform', 'translate(13.5,5)')

      this.BrushCustomGroup2.append('path')
        .attr('d', 'M-2,-6  L-2,6  M2,-6  L2,6')
        .attr('fill', '#404040')

      this.gBrush.call(this.brush.move, [this.xMin, this.xMax]
          .map(this.xScale));

    },
    brushEnd() {

      const eventSelection = d3.event.selection;
      if (eventSelection === null) {
        this.handle.attr("display", "none");
        this.resetBrush()
      } else {

        //Show resetBrush button
        this.selectionBrush = true
        const [x1, x2] = d3.event.selection;
        const years = [
          this.xScale.invert(x1),
          this.xScale.invert(x2)
        ]
        this.handle.attr("display", null).attr("transform", (d, i) => `translate(${eventSelection[i] - 15},${height / 3})`);
        //pass years to parent(line)
        this.$emit("callBrushed", years);
        if(this.stopAnimation === true) {
          this.container
            .selectAll('.path')
            .attr('stroke-dasharray', 0)
        }

        this.stopAnimation = true

      }
    },
    resetBrush() {

      //Reset brush
      this.gBrush.call(this.brush.move, [this.xMinDefault, this.xMaxDefault]
          .map(this.xScale));
      //Hide resetBrush button
      this.selectionBrush = false
      this.chartBrush
    }
  }
};
</script>
<style>
.container-brush {
  fill: #F5F5F5;
  width: 100%;
  position: absolute;
  bottom: 6.6px;
  left: 0;
}

.brush {
  fill: #E8E8E8;
}

.handle.handle--e,
.handle.handle--w {
  display: none !important;
}

.handle--custom:hover g,
.handle--custom:active g {
  fill-opacity: 1;
  transition: fill-opacity .3s ease-in;
}

.overlay,
.selection {
  fill: #E8E8E8;
  fill-opacity: .4;
  rx: 3;
}

.selection {
  fill-opacity: 1;
}

.selection:hover {
  fill-opacity: .7;
  transition: fill-opacity .3s ease-in;
}

.reset-brush {
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;
  width: 30px;
  height: 28px;
}

.reset-brush:hover g {
  fill-opacity: 1;
}

.reset-brush-icon {
  width: 28px;
  height: 28px;
  display: inline-block;
}
</style>
