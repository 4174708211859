<template>
  <div class="container-slider-timeline">
    <button
      class="container-slider-timeline__button"
      @click="playPause"
    >
      <template v-if="showPlay">
        <v-icon
          name="play"
          scale="1"
        />
      </template>
      <template v-else>
        <v-icon
          name="pause"
          scale="1"
        />
      </template>
    </button>
  </div>
</template>
<script>
import "vue-awesome/icons/play";
import "vue-awesome/icons/pause";
import Icon from "vue-awesome/components/Icon";
import * as d3 from 'd3';
export default {
  name: "SliderTimeLine",
  components: {
    "v-icon": Icon
  },
  props: {
    data: {
      type: Array,
      default: () => []
    },
    container: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      svg: '',
      slider: '',
      handle: '',
      countX: '',
      showPlay: true,
      moving: false,
      currentValue: 0,
      targetValue: '',
      timer: '',
      yearIndex: 0,
      addMoreYearsToDomain: 1,
      yearsArray: [],
      totalYears: [],
    };
  },
  created() {
    this.yearsArray = [...new Set(this.data.map(d => d.year))].sort((a, b) => a - b);
  },
  mounted() {
    this.initTimeline()
  },
  methods: {
    initTimeline() {
      const container = document.querySelector(this.container)
      const svgWidth = container.offsetWidth - 30
      this.targetValue = svgWidth

      const self = this

      this.svg = d3.select(`${this.container} .container-slider-timeline`)
        .append('svg')
        .attr('class', 'container-slider-timeline__svg')
        .attr('width', svgWidth)
        .attr('height', 30)

      this.countX = d3
        .scaleTime()
        //To avoid the slider from ending on the last year that has data, add more years to the domain
        .domain([new Date(d3.min(this.yearsArray), 1), new Date(d3.max(this.yearsArray) + this.addMoreYearsToDomain, 1)])
        .range([0, this.targetValue])
        .clamp(true)

      this.slider = this.svg.append("g")
        .attr("class", "slider")

      this.slider.append("line")
        .attr("class", "container-slider-timeline__track")
        .attr("x1", this.countX.range()[0])
        .attr("x2", this.countX.range()[1])
        .attr("y1", 15)
        .attr("y2", 15)
        .call(d3.drag()
          .on("start drag", function() {
            self.closestYear(d3.event.x)
          })
        );

      this.slider.insert("g", ".track-overlay")

      this.handle = this.slider.insert("rect", ".track-overlay")
        .attr("class", "container-slider-timeline__handle")
        .attr("width", 10)
        .attr("height", 30)
        .style('fill', '#558380')
    },
    playPause() {
      this.showPlay = !this.showPlay
      if (this.showPlay) {
        this.moving = false
        clearInterval(this.timer);
      } else {
        this.moving = true
        this.currentValue = this.currentValue >= this.countX.range()[1] ? 0 : this.currentValue
        this.yearIndex = this.currentValue === 0 ? 0 : this.yearIndex
        this.timer = setInterval(this.steps, 200);
      }
    },
    steps() {
      this.update(this.countX.invert(this.currentValue));
      this.totalYears = (d3.max(this.yearsArray) + this.addMoreYearsToDomain) - d3.min(this.yearsArray)
      this.currentValue = this.currentValue + (this.targetValue / (this.totalYears));
      this.yearIndex++
      if (this.currentValue > this.targetValue) {
        this.moving = false;
        this.showPlay = true
        clearInterval(this.timer);
      }
    },
    update(value) {
      const getYear = value.getFullYear()
      this.handle
        .transition()
        .duration(300)
        .ease(d3.easeLinear)
        .attr("x", this.countX(value))
        .transition()
        .duration(300)
        .ease(d3.easeLinear)
      if(this.yearsArray.find(d => d === getYear)) {
        const currentYear = this.yearsArray.find(d => d === getYear)
        const filterData = this.data.filter(({ year }) => year <= currentYear)
        this.$emit("updateMap", filterData, currentYear);
      }
    },
    closestYear(value) {
      /*The scaleTime is built from the first year to the last year,
      in this case, between 1870 and 2015, but the range is only 24 years old.
      So if we click on 1874, we look for the closest year with data, and it shows it.*/
      const date = this.countX.invert(value)
      const getYear = date.getFullYear()
      const closestOnlyYear = this.yearsArray.reduce((prev, curr) =>
        (Math.abs(curr - getYear) < Math.abs(prev - getYear) ? curr : prev)
      );
      const closestDate = new Date(closestOnlyYear , 1)
      const index = this.yearsArray.findIndex(year => year === closestOnlyYear);
      this.yearIndex = index
      this.currentValue = value
      this.update(closestDate)
    }
  }
};
</script>

<style>
.container-slider-timeline {
  margin-bottom: .5rem;
  display: flex;
  align-items: center;
}

.container-slider-timeline__button {
  border: 0;
  border-radius: 0;
  height: 30px;
  width: 30px;
  background-color: #558380;
  color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container-slider-timeline__svg {
  display: inline-block;
}

.container-slider-timeline__track {
  stroke: #000;
  stroke-opacity: .3;
  stroke-width: 30px;
  cursor: move;
}

.container-slider-timeline__track-overlay {
  pointer-events: stroke;
  stroke-width: 50px;
  stroke: transparent;
  cursor: crosshair;
}

.container-slider-timeline__handle {
  pointer-events: none;
}

</style>
