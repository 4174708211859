export const CHARTS = [
  {
    id: 0,
    icon: "bar",
    title: "Dispersion"
  },
  {
    id: 1,
    icon: "area",
    title: "Espectro"
  },
  {
    id: 2,
    icon: "line",
    title: "Line"
  },
  {
    id: 3,
    icon: "line",
    title: "Stacked"
  },
  {
    id: 4,
    icon: "line",
    title: "Map"
  },
  {
    id: 5,
    icon: "line",
    title: "MapContainer"
  },
  {
    id: 6,
    icon: "line",
    title: "HeatMap"
  }
];
