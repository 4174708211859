import { EventBus } from "./bus";

export const VisualizationMixin = {
  series: {
    x: "x",
    y: "y"
  },
  props: {
    id: String,
    factory: Object
  },
  data() {
    return {
      varX: [],
      varY: [],
      range: [],
      loading: false,
      empty: false,
      currentRange: null,
      currentVarX: null,
      currentVarY: null,
      defaultRange: null,
      defaultVarX: null,
      defaultVarY: null,
      varXNames: [],
      varYNames: [],
      groupProps: {},
      maxHeight: null,
      filterGroup: null,
      selectorStacked: false,
      filterFirstGroup: false
    };
  },
  computed: {
    rangeSorted() {
      return this.range
        .slice(0)
        .sort()
        .reverse();
    }
  },
  async created() {
    if (this.factory.config.ranges) {
      const { ranges } = this.factory.config;
      this.range = ranges instanceof Function ? ranges() : ranges;

      // TODO: Esto debería ir después de obtener las series, ya que depende de los datos
      const { defaults = {} } = this.properties;
      this.defaultRange =
        parseInt(defaults.range) ||
        this.range
        .slice(0)
        .sort()
        .reverse()[0];
    }

    if (this.pivotTableShow) {
      this.varXNamesPivotTable = await this.getSeriesPivotTable() || []
    }

    this.selectorStacked = this.factory.config.charts[0].type === "Stacked" ? true : false

    this.getSeries();

  },
  mounted() {
    const { chart } = this.$refs;

    if (chart) {
      EventBus.$emit(`chart-${this.id}--markup`, chart);
    }
  },
  methods: {
    getSeriesUpdateStacked() {
      this.selectorStacked = true
      this.getSeries()
    },
    getSeriesUpdateLine() {
      this.selectorStacked = false
      this.getSeries()
    },
    async getSeries() {
      const series = this.factory.load("series");
      let data = Array.isArray(series) ? series : await series.get();

      if (data) {
        this.varX = data;
        this.varY = data;

        if (data.some(d => d.group)) {
          this.groupProps = {
            groupLabel: "key",
            groupValues: "values"
          };

          const groupBy = (xs, key) =>
            xs.reduce((rv, x) => {
              (rv[x[key]] = rv[x[key]] || []).push(x);
              return rv;
            }, {});

          const names = Object.entries(groupBy(data, "group")).map(d => ({ key: d[0], values: d[1].map(g => g.name) }));
          this.varXNames = names;
          this.varYNames = names;
        } else {
          const names = data.map(d => d.name);
          this.varXNames = names;
          this.varYNames = names;
        }

        // Set defaults
        this.setIndicatorDefaults();
      }
    },
    async getSeriesPivotTable() {
      const { groups: dataPivotTable } = await this.fetchData();
      const elements = [...new Set(dataPivotTable.map(d => d[this.pivotTableValue]))]
      return elements.sort((a, b) => a.localeCompare(b))
    },
    async fetchData() {
      this.loading = true;

      const params = {
        range: this.currentRange,
        varX: this.currentVarX,
        varY: this.currentVarY
      };

      const data = await this.factory.load("data").get(params);
      this.getSeries()
      const mapCharts = ['Map', 'MapContainer', undefined, 'HeatMap']
      if (mapCharts.includes(this.factory.config.charts[0].type)) {
        return data
      }

      this.loading = false;

      if (!data.length) {
        this.empty = true;
        this.forceFetch();
      } else {
        this.empty = false;
        this.data = data;

        EventBus.$emit(`chart-${this.id}--data`, data);
      }
    },
    forceFetch() {
      const { ranges: definedRanges } = this.factory.config;

      // If definedRanges is not a function, it means is an array, the default array,
      // so it's nonsense to look for a common range because it will be always the same
      if (definedRanges && definedRanges instanceof Function) {
        const availableRangesX = definedRanges(this.currentVarX) || [];
        const availableRangesY = definedRanges(this.currentVarY) || [];

        let common;

        if (availableRangesX.length && availableRangesY.length) {
          common = availableRangesX.filter(value => availableRangesY.includes(value));
        } else if (!availableRangesX.length && availableRangesY.length) {
          common = availableRangesY;
        } else if (availableRangesX.length && !availableRangesY.length) {
          common = availableRangesX;
        }

        if (common) {
          // https://gist.github.com/vipickering/6552366#gistcomment-2719344
          const nearest = arr => val => arr.reduce((p, n) => (Math.abs(p) > Math.abs(n - val) ? n - val : p), Infinity) + val;

          this.currentRange = nearest(common)(this.currentRange);
          this.defaultRange = this.currentRange;
        }
      }
    },
    render() {
      const { data = [] } = this.chart;
      if (data.length) {
        EventBus.$emit(`chart-${this.id}--data-filter`, this.chart.data);
      }

      this.chart.draw();
      this.setHeights(); // fix chart heights (after render)
    },
    setIndicatorDefaults() {
      const { defaults = {} } = this.properties;

      let xAux = !this.isString(this.varXNames[0]) ?
        !this.isGroup(this.varXNames[0]) ?
        this.varXNames[0] :
        this.varXNames[0][this.groupProps.groupValues][0] :
        this.varX[0].name;
      let yAux = !this.isString(this.varYNames[1]) ?
        !this.isGroup(this.varYNames[1]) ?
        this.varYNames[1] :
        this.varXNames[1][this.groupProps.groupValues][1] :
        this.varY[1].name;

      if (defaults.x) {
        const xMatch = this.isString(defaults.x) ?
          this.varX.find(v => v.id === defaults.x) :
          this.varX.filter(v => defaults.x.includes(v.id));

        if (xMatch) {
          xAux = Array.isArray(xMatch) ? xMatch.map(d => d.name) : xMatch.name;
        }
      }

      if (defaults.y) {
        const yMatch = this.isString(defaults.y) ?
          this.varY.find(v => v.id === defaults.y) :
          this.varY.filter(v => defaults.y.includes(v.id));

        if (yMatch) {
          yAux = Array.isArray(yMatch) ? yMatch.map(d => d.name) : yMatch.name;
        }
      }

      this.defaultVarX = xAux;
      this.defaultVarY = yAux;
    },
    handleVarX(indicator) {
      const { id } = indicator;

      if (this.currentVarX !== id) {
        this.currentVarX = id;
      }
    },
    handleVarY(indicator) {
      const { id } = indicator;

      if (this.currentVarY !== id) {
        this.currentVarY = id;
      }
    },
    handleRange(range) {
      if (this.currentRange !== range) {
        this.currentRange = range;
      }
    },
    isString(str) {
      return typeof str === "string" || str instanceof String;
    },
    isGroup(str) {
      return typeof str === "object" && str !== null && this.groupProps;
    },
    setHeights() {
      const { height, margin = {} } = this.chart;
      const fullHeight = height + (margin.top + margin.bottom * 2);

      // Dropdown height
      if (this.maxHeight !== fullHeight) {
        this.maxHeight = fullHeight;
      }
    }
  }
};
