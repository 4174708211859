<template>
  <Multiselect
    class="multiselect--button"
    v-model="value"
    :options="options"
    :placeholder="placeholder"
    :multiple="true"
    :open-direction="openDirection"
    select-label
    selected-label
    deselect-label
    @input="handleSelect"
  >
    <template slot="caret">
      <div class="multiselect__select">
        <v-icon name="plus-circle" scale="1.25"></v-icon>
      </div>
    </template>
  </Multiselect>
</template>

<script>
import Selector from "./Selector";
import "vue-awesome/icons/plus-circle";
import Icon from "vue-awesome/components/Icon";

export default {
  name: "SelectorButton",
  extends: Selector,
  components: {
    "v-icon": Icon
  },
  props: {
    placeholder: String,
    multiple: Boolean
  }
};
</script>

<style>
.multiselect--button {
  width: 250px;
  background: rgba(216, 216, 216, 0.2);
  border-radius: 3px;
  display: flex;
  align-items: center;
  padding: 12px 18px;
  box-sizing: border-box;
}

.multiselect--button .multiselect__select::before {
  content: none;
}

.multiselect--button .multiselect__select {
  position: relative;
  height: auto;
  width: auto;
  top: 0;
  right: 0;
  padding: 0;
  color: #80c8cf;
  transform: none;
}

.multiselect--button .multiselect__tags {
  padding: 0;
  border: 0;
  background: none;
  min-height: auto;
  margin-left: 2rem;
}

.multiselect--button .multiselect__tag {
  margin-bottom: 0;
  margin-right: 3px;
}

.multiselect--button .multiselect__placeholder {
  margin-bottom: 0;
  display: inherit;
  padding-top: 0;
  color: inherit;
  font-size: 14px;
  color: #4a4a4a;
}

.multiselect--button .multiselect__input,
.multiselect--button .multiselect__single {
  background: none;
  margin-bottom: 0;
  font-size: 14px;
}

.multiselect--button:not(.multiselect--above) .multiselect__content-wrapper {
  left: 0;
  top: 0;
  margin-top: 40px;
}

.multiselect--button.multiselect--above .multiselect__content-wrapper {
  left: 0;
}
</style>
