import App from "./App.vue";
import Vue from "vue";
import vueCustomElement from "vue-custom-element";
import VueI18n from "vue-i18n";
import FloatingVue from "floating-vue";

Vue.config.productionTip = false;

Vue.use(vueCustomElement);
Vue.customElement("populate-charts", App);

Vue.use(FloatingVue);
Vue.use(VueI18n);

if (process.env.NODE_ENV === "development") {
  import("./development.connectors")
}
