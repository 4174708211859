<template>
  <div class="chart-type__container" v-if="chartsAvailable">
    <template v-for="(chart, index) in chartsAvailable">
      <ChartType
        :key="chart.id"
        :chart="chart"
        :default="(index === 0)"
        :active="(chartSelected || {}).id === chart.id"
        @handleClick="handleClick"
      />
    </template>
  </div>
</template>

<script>
import ChartType from "./ChartType";
import { EventBus } from "../../assets/bus";
import { CHARTS } from "../../assets/constants";

export default {
  components: {
    ChartType
  },
  props: {
    id: {
      type: String,
      default: ''
    },
    activeCharts: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      chartsAvailable: null,
      chartSelected: null
    };
  },
  created() {
    const keys = this.activeCharts.map(a => a.type);
    this.chartsAvailable = CHARTS.filter(ch => keys.includes(ch.title)).sort(
      (a, b) => keys.indexOf(a.title) > keys.indexOf(b.title)
    );
  },
  methods: {
    handleClick(value) {
      this.chartSelected = this.chartsAvailable.find(d => d.id === value);

      if (this.chartSelected) {
        EventBus.$emit(`chart-selected--${this.id}`, this.chartSelected.title);
      }
    }
  }
};
</script>

<style>
.chart-type__container {
  --items-gap: 2px;
  display: flex;
  flex-wrap: wrap;
  padding: 2px 0;
  padding: var(--items-gap, 2px) 0;
  margin-left: -2px;
  margin-left: calc(-1 * var(--items-gap, 2px));
}
</style>
