<template>
  <div class="legend legend-right">
    <div :key="item.id" v-for="item in items" class="legend-right-container">
      <div
        class="legend-item-right"
        :class="{ 'legend-item--selected': item.isSelected }"
        @click="handleClick(item)"
        @mouseenter="handleMouseenter(item)"
        @mouseout="handleMouseout(item)"
      >
        <div class="name"></div>
      </div>
      <div class="close" @click.stop="handleCloseClick(item)" @mouseenter="handleMouseenter(item)" @mouseout.stop="handleMouseenter(item)">
        <v-icon name="times" scale="0.75"></v-icon>
      </div>
    </div>
  </div>
</template>

<script>
import "vue-awesome/icons/times";
import Icon from "vue-awesome/components/Icon";
import { debounce } from "debounce";

export default {
  components: {
    "v-icon": Icon
  },
  props: {
    items: Array,
    color: Function,
    properties: Object
  },
  created() {

    this.handleMouseenter = debounce(this.handleMouseenter, 250);
    this.handleMouseout = debounce(this.handleMouseout, 250);
  },
  methods: {
    handleClick(item) {
      // Toggle selection
      item.isSelected = !item.isSelected;
      // Index of the element toggled
      const i = this.items.findIndex(d => d.id === item.id);

      // Add new reactive property (array)
      this.$set(this.items, i, {
        ...this.items[i],
        isSelected: item.isSelected
      });

      // Emit event
      this.$emit("onClick", item, item.isSelected);
    },
    handleMouseenter(item) {
      this.$emit("onMouseenter", item);
    },
    handleMouseout(item) {
      this.$emit("onMouseout", item);
    },
    handleCloseClick(item) {
      this.$emit("onCloseClick", item);
    }
  }
};
</script>

<style>
.legend-right {
  position: absolute;
}

.legend-right .legend-item-right-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  display: block;
  width: 140px;
  cursor: pointer;
}

.legend-item-right:hover {
  background-color: #fff;
  padding-bottom: 1rem;
  z-index: 1;
}

.legend-item-right:hover .name {
  text-overflow: normal;
  overflow: initial;
  white-space: normal;
  font-weight: 800;
}

.legend-item-right.legend-item--selected {
  background-color: #fff;
  border: 0;
}

.legend-item--selected .name {
  font-weight: 800;
}

.legend-right-container .close {
  opacity: 0;
  transition: opacity .3s ease-in;
  max-width: max-content;
  border-radius: 4px;
  padding: 2px 4px;
  position: absolute;
  width: 20px;
  height: 20px;
  top: 0;
  right: -16px;
  cursor: pointer;
}

.legend-right-container:hover .close {
  opacity: 1;
}

@media only screen and (max-width: 525px) {

  .legend-right .legend-item-right-text {
    width: 80px;
  }

}

</style>
