<template>
  <keep-alive>
    <transition name="component-fade" mode="out-in">
      <component
        :is="currentChart"
        :properties="currentConfig"
        :factory="factory"
        :id="id"
        :language="language"
        :key="language"
      />
    </transition>
  </keep-alive>
</template>

<script>
import { EventBus } from "../assets/bus";

export default {
  props: {
    id: {
      type: String,
      default: ''
    },
    language: {
      type: String,
      default: ''
    },
    factory: {
      type: Object,
      default: () => {}
    },
    config: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      currentChart: null,
      currentConfig: null
    };
  },
  created() {
    EventBus.$on(`chart-selected--${this.id}`, this.handleChart);
  },
  methods: {
    handleChart(chart) {
      const { charts } = this.factory.config;
      this.currentConfig = charts.find(c => c.type === chart);
      if(chart === "Stacked") {
        chart = "Line"
      }
      this.currentChart = () => import(`./visualizations/${chart}`);
    }
  },
  beforeDestroy() {
    EventBus.$off(`chart-selected--${this.id}`, this.handleChart);
  }
};
</script>

<style>
.component-fade-enter-active,
.component-fade-leave-active {
  transition: opacity 0.3s ease;
}
.component-fade-enter,
.component-fade-leave-to {
  opacity: 0;
}
</style>
