<template>
  <div>
    <div class="row row--theme">
      <Selector
        v-if="data && data.length && aggregationsAvailable && aggregationsAvailable.length"
        class="multiselect--min-width multiselect--keep-placeholder"
        :options="aggregationsAvailable"
        :placeholder="properties.btnPlaceholder"
        :multiple="true"
        :default="defaultAggregation"
        :max-height="maxHeight"
        track-by="name"
        label="name"
        open-direction="bottom"
        @handleSelect="handleSelectorButton"
      ></Selector>
      <Selector
        v-if="range && range.length"
        :options="rangeSorted"
        :default="defaultRange"
        open-direction="bottom"
        @handleSelect="handleSelector"
      ></Selector>
    </div>

    <Loader :loading="loading" :empty="empty" :info="currentRange" />

    <div class="chart-wrapper">
      <div id="dispersion-chart" ref="chart">
        <Legend
          v-if="data && data.length"
          :items="currentAggregationSorted"
          :color="aggregationColor"
          @onClick="handleLegendClick"
          @onMouseenter="handleLegendMouseenter"
          @onMouseout="handleLegendMouseout"
          @onCloseClick="handleLegendCloseClick"
        />
        <Tooltip ref="tooltip" />
      </div>
    </div>

    <div class="row">
      <div class="row" v-if="varX && varX.length">
        <Selector
          class="multiselect--min-width multiselect--theme"
          :options="varXNames"
          :default="defaultVarX"
          open-direction="auto"
          @handleSelect="handleSelector($event, 'x')"
          v-bind="groupProps"
        ></Selector>
        <div class="arrow arrow--right"></div>
      </div>
      <div class="row" v-if="varY && varY.length">
        <Selector
          class="multiselect--min-width multiselect--theme"
          :options="varYNames"
          :default="defaultVarY"
          open-direction="auto"
          @handleSelect="handleSelector($event, 'y')"
          v-bind="groupProps"
        ></Selector>
        <div class="arrow arrow--top"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { VisualizationMixin } from "../../assets/mixins";

import Dispersion from "../../assets/charts/Dispersion";
import Selector from "../selectors/Selector";
import Legend from "../selectors/Legend";
import Loader from "../Loader";
import Tooltip from "../Tooltip";

export default {
  name: "Dispersion",
  components: {
    Selector,
    Loader,
    Legend,
    Tooltip
  },
  mixins: [VisualizationMixin],
  data() {
    return {
      data: [],
      chart: null,
      currentAggregation: [],
      defaultAggregation: []
    };
  },
  props: {
    properties: Object
  },
  computed: {
    aggregationsAvailable() {
      const { aggregationProp } = this.properties;
      const aggs = aggregationProp
        ? [...new Set(this.data.map(d => d[aggregationProp]))]
            .sort(Intl.Collator().compare)
            .map((d, i) => ({ id: i, name: d }))
        : [];

      return aggs;
    },
    currentAggregationSorted() {
      const collator = new Intl.Collator();
      return this.currentAggregation
        .slice(0)
        .sort((a, b) => collator.compare(a.name, b.name));
    }
  },
  created() {
    this.setAggregationDefault();
  },
  mounted() {
    const defaultSizes = {
      minHeight: 320,
      maxWidth: 1440,
      aspectRatio: 2
    };

    const props = {
      ...defaultSizes,
      ...this.properties,
      ctx: this.$refs.chart,
      data: this.data,
      tooltipContainer: this.$refs.tooltip.$refs.tooltip
    };

    this.chart = new Dispersion(props);
  },
  methods: {
    setChartData() {
      this.chart.varX = this.currentVarX;
      this.chart.varY = this.currentVarY;
      this.chart.currentAggregation = this.currentAggregationSorted.map(
        d => d.name
      );
      this.chart.data = this.data.filter(d => !d.hidden);
      this.render();
    },
    preprocess() {
      this.data = this.setHiddenStatus(
        this.data,
        this.currentAggregation.map(d => d.name),

      );

      this.setChartData();
    },
    setHiddenStatus(data = [], currentSelection = []) {
      return data.map(d =>
        currentSelection.includes(d[this.properties.aggregationProp]) ||
        !currentSelection.length
          ? { ...d, hidden: false }
          : { ...d, hidden: true }
      );
    },
    setAggregationDefault() {
      const { defaults = {} } = this.properties;

      if (defaults.aggregation && defaults.aggregation.length) {
        this.defaultAggregation = defaults.aggregation.map(d => ({
          name: d
        }));
      }
    },
    handleSelectorButton(element) {
      if (element) {
        this.currentAggregation = element;
        this.preprocess();
        this.$root.$emit('updateDispersionAggregation', this.currentAggregation)
      }
    },
    async handleSelector(...args) {
      const [event, param] = args;

      if (param === this.$options.series.x) {
        const x = this.varX.find(d => d.name === event);
        this.$root.$emit('updateDispersionX', x)
        if (x) {
          this.handleVarX(x);
        }
      }

      if (param === this.$options.series.y) {
        const y = this.varY.find(d => d.name === event);
        this.$root.$emit('updateDispersionY', y)

        if (y) {
          this.handleVarY(y);
        }
      }

      if (this.range.includes(event)) {
        this.handleRange(event);
        this.$root.$emit('updateDispersionRange', this.currentRange)
      }

      if (this.currentRange && this.currentVarX && this.currentVarY) {
        await this.fetchData();
        this.preprocess();
      }
    },
    handleLegendClick(element, status) {
      this.chart.onClick(element.name, status);
    },
    handleLegendMouseenter(element) {
      this.chart.onMouseenter(element.name);
    },
    handleLegendMouseout(element) {
      this.chart.onMouseout(element.name);
    },
    handleLegendCloseClick(element) {
      if (this.currentAggregation.some(d => d.name === element.name)) {
        const i = this.currentAggregation.findIndex(
          d => d.name === element.name
        );

        if (this.chart.hasOwnProperty("currentHighlights")) {
          delete this.chart.currentHighlights[i];
        }

        this.currentAggregation.splice(i, 1);
        this.$root.$emit('updateDispersionAggregation', this.currentAggregation);
        this.preprocess();
      }
    },
    aggregationColor(agg) {
      const { palette = this.chart.palette } = this.properties;
      const i = this.currentAggregationSorted.findIndex(d => d.name === agg);

      return palette[i % palette.length];
    }
  }
};
</script>

<style>
#dispersion-chart {
  width: 100%;
  position: relative;
}

.chart-wrapper {
  height: 720px; /* half of the width */
  height: var(--chart-height, 720px);
}

.arrow {
  background: #979797;
  height: 1px;
  width: 50px;
  position: relative;
  display: none;
}

.arrow:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 4px 0 4px 8px;
  border-color: transparent transparent transparent #979797;
  right: -3px;
  top: -3px;
}

.arrow--right {
  margin-left: 16px;
}

.arrow--top {
  margin-left: -50px;
  margin-top: -50%;
  transform: rotate(-90deg);
}

@media screen and (min-width: 768px) {
  .arrow {
    display: block;
  }
}
</style>
