import * as d3 from "d3";

export default class {
  constructor(props) {
    if (!props.ctx) {
      return new Error("No markup provided.");
    }

    this.container = d3.select(props.ctx);
    this.data = props.data || [];
    this.props = props;

    // Options
    this.minHeight = props.minHeight;
    this.maxWidth = props.maxWidth;
    this.xAxisProp = props.xAxisProp;
    this.varX = props.varX;
    this.yAxisProp = props.yAxisProp;
    this.varY = props.varY;
    this.aggregationProp = props.aggregationProp;
    this.currentAggregation = props.currentAggregation || [];
    this.tooltip = props.tooltip || this.defaultTooltip;
    this.palette = props.palette || d3.schemeCategory10;
    this.defaultColor = props.defaultColor || this.palette[Math.floor(Math.random() * this.palette.length)];
    this.ticksXAxis = props.ticksXAxis !== undefined ? props.ticksXAxis : 3;
    this.ticksYAxis = props.ticksYAxis !== undefined ? props.ticksYAxis : 3;
    this.tickFormat = props.tickFormat || d3.format("~s");
    this.transitionTime = props.transitionTime !== undefined ? props.transitionTime : 1000;
    this.xDefaultScale = d3.scaleLinear();
    this.xScaleFunction = props.xScaleFunction || this.xDefaultScale;
    this.yDefaultScale = d3.scaleLinear();
    this.yScaleFunction = props.yScaleFunction || this.yDefaultScale;
    this.styles = this.defaultStyles(props.styles);
    this.tooltipContainer = props.tooltipContainer ? d3.select(props.tooltipContainer) : false;

    // Create main elements
    this.svg = this.container.append("svg");
    this.g = this.svg.append("g");
    this.g.append("g").attr("class", "x axis");
    this.g.append("g").attr("class", "y axis");

    if (this.data.length) {
      this.draw();
    }

    window.addEventListener("resize", this.draw.bind(this));
  }

  draw() {
    if (this.data.length) {
      this.responsiveness();
      this.setElements();
      this.handleCircles();
    }
  }

  responsiveness() {
    this.isMobile = document.documentElement.clientWidth < 768;
    this.margin = {
      top: this.props.marginTop !== undefined ? this.props.marginTop : 25,
      right: this.isMobile ? 10 : this.props.marginRight !== undefined ? this.props.marginRight : 50,
      bottom: this.props.marginBottom !== undefined ? this.props.marginBottom : 50,
      left: this.isMobile ? 15 : this.props.marginLeft !== undefined ? this.props.marginLeft : 15
    };
    this.aspectRatio = this.isMobile ? 1 : this.props.aspectRatio;
  }

  setElements() {
    // Dimensions
    this.width = Math.min(this.maxWidth, +this.container.node().getBoundingClientRect().width - this.margin.left - this.margin.right);
    this.height = Math.max(this.minHeight, this.width / this.aspectRatio - this.margin.top - this.margin.bottom);

    // Scales & Ranges
    const xScale = () => this.xScaleFunction.apply(null, [this.varX, d3]) || this.xDefaultScale;
    this.xScale = xScale()
      .range([0, this.width])
      .clamp(true)
      .domain(d3.extent(this.data, d => d[this.xAxisProp]).map(d => (d === 0 ? d + 1e-1 : d)));

    const yScale = () => this.yScaleFunction.apply(null, [this.varY, d3]) || this.yDefaultScale;
    this.yScale = yScale()
      .range([this.height, 0])
      .clamp(true)
      .domain(d3.extent(this.data, d => d[this.yAxisProp]).map(d => (d === 0 ? d + 1e-1 : d)));

    this.categoryScale = d3
      .scaleOrdinal()
      .range(this.palette)
      .domain(this.currentAggregation);

    // Positions
    this.svg
      .attr("width", this.width + this.margin.left + this.margin.right)
      .attr("height", this.height + this.margin.top + this.margin.bottom);
    this.g.attr("transform", `translate(${this.margin.left},${this.margin.top})`);

    // Create axes
    this.g
      .select(".x.axis")
      .attr("transform", `translate(0,${this.height})`)
      .call(this.customXAxis.bind(this));

    this.g
      .select(".y.axis")
      .attr("transform", `translate(${this.width},0)`)
      .call(this.customYAxis.bind(this));
  }

  handleCircles() {
    const circles = this.g.selectAll(`circle.${this.styles.circle.cssClass}`).data(this.data);

    circles.exit().remove();

    const circlesEnter = circles
      .enter()
      .append("circle")
      .attr("class", this.styles.circle.cssClass)
      .on("mouseover", this.onCircleMouseover.bind(this))
      .on("mouseout", this.onCircleMouseout.bind(this));

    circles
      .merge(circlesEnter)
      .transition()
      .duration(this.transitionTime)
      .attr("cx", d => this.xScale(d[this.xAxisProp]))
      .attr("cy", d => this.yScale(d[this.yAxisProp]))
      .attr("fill", d => (this.currentAggregation.length ? this.categoryScale(d[this.aggregationProp]) : this.defaultColor))
      .attr("fill-opacity", this.styles.circle.fillOpacity)
      .attr("stroke", this.styles.circle.stroke)
      .attr("r", this.styles.circle.radius)
      .attr("opacity", d => this.handleOpacity(d))
      .attr("cursor", "pointer");
  }

  onCircleMouseover(d) {
    if (this.tooltipContainer) {
      this.tooltipContainer
        .html(this.tooltip(d))
        .style("opacity", 1)
        .style("position", "absolute")
        .style("left", `${this.xScale(d[this.xAxisProp]) + this.margin.left}px`)
        .style("top", `${this.yScale(d[this.yAxisProp]) + this.margin.top - this.styles.circle.radius * 2}px`);
    }
  }

  onCircleMouseout() {
    if (this.tooltipContainer) {
      this.tooltipContainer
        .style("opacity", 0)
        .style("left", "-100%")
        .style("top", "-100%");
    }
  }

  onClick(selection, status) {
    if (status) {
      this.currentHighlights = typeof this.currentHighlights !== "object" ? {} : this.currentHighlights;
      this.currentHighlights[selection] = status;
    } else {
      delete this.currentHighlights[selection];
    }

    this.g
      .selectAll(`circle.${this.styles.circle.cssClass}`)
      .transition()
      .attr("opacity", d =>
        (d[this.aggregationProp] === selection && this.currentHighlights[selection]) || this.currentHighlights[d[this.aggregationProp]]
          ? 1
          : 0.1
      );
  }

  onMouseenter(selection) {
    this.g
      .selectAll(`circle.${this.styles.circle.cssClass}`)
      .transition()
      .attr("opacity", d => (d[this.aggregationProp] === selection ? 1 : 0.1));
  }

  onMouseout() {
    this.g
      .selectAll(`circle.${this.styles.circle.cssClass}`)
      .transition()
      .attr("opacity", d => this.handleOpacity(d));
  }

  handleOpacity(d) {
    // No object
    if (!this.currentHighlights) return 1;
    // Empty object
    if (Object.keys(this.currentHighlights).length === 0 && this.currentHighlights.constructor === Object) return 1;
    // Selected property
    if (this.currentHighlights[d[this.aggregationProp]]) return 1;
    // Otherwise
    return 0.1;
  }

  customXAxis(g) {
    g.call(
      d3
        .axisBottom(this.xScale)
        .tickPadding(this.styles.circle.radius * 1.5)
        .ticks(this.ticksXAxis)
        .tickSize(-this.height)
        .tickFormat(this.tickFormat)
    );
    g.selectAll(".domain").remove();
    g.selectAll(".x.axis line")
      .attr("stroke", this.styles.axis.x.stroke)
      .attr("stroke-dasharray", this.styles.axis.x.strokeDasharray);
    g.selectAll(".x.axis text")
      .attr("fill", this.styles.axis.x.fill)
      .attr("font-size", this.styles.axis.x.fontSize)
      .attr("font-weight", this.styles.axis.x.fontWeight);
  }

  customYAxis(g) {
    g.call(
      d3
        .axisRight(this.yScale)
        .ticks(this.ticksYAxis)
        .tickSize(-this.width)
        .tickFormat(this.tickFormat)
    );
    g.selectAll(".domain").remove();
    g.selectAll(".y.axis line")
      .attr("stroke", this.styles.axis.y.stroke)
      .attr("stroke-dasharray", this.styles.axis.y.strokeDasharray);

    g.selectAll(".y.axis text")
      .attr("fill", this.styles.axis.y.fill)
      .attr("font-size", this.styles.axis.y.fontSize)
      .attr("font-weight", this.styles.axis.y.fontWeight)
      .attr("text-anchor", this.isMobile ? "end" : null)
      .attr("dy", this.isMobile ? "-0.55em" : "0.32em");
  }

  defaultTooltip(d) {
    return `<pre>${JSON.stringify(d, null, 2)}</pre>`;
  }

  defaultStyles(styles = {}) {
    return {
      circle: {
        cssClass: "circle",
        radius: 12,
        fillOpacity: 0.7,
        stroke: "#fff",
        ...styles.circle
      },
      axis: {
        x: {
          stroke: "#dcdcdc",
          strokeDasharray: "2, 2",
          fill: "#a5a5a5",
          fontSize: "1em",
          fontWeight: "bold",
          ...(styles.axis || {}).x
        },
        y: {
          stroke: "#dcdcdc",
          strokeDasharray: "2, 2",
          fill: "#a5a5a5",
          fontSize: "1em",
          fontWeight: "bold",
          ...(styles.axis || {}).y
        }
      },
      legend: {
        cssClass: "category",
        width: 150,
        widthClose: 20,
        height: 30,
        fill: "#01909e",
        fillClose: "#01909e",
        fillOpacity: 0.1,
        fillOpacityClose: 0.3,
        borderRadius: 4,
        borderRadiusClose: 2,
        stroke: "#d8d8d8",
        ...styles.legend
      }
    };
  }
}
