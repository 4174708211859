import { feature } from 'topojson';
import * as dataGeoJson from "../../lib/countries-50m.json";
import { select, selectAll } from 'd3-selection';
import { scaleQuantile } from 'd3-scale';
import { zoom } from 'd3-zoom'
import { geoMercator, geoPath } from 'd3-geo';
import { format } from 'd3-format';
import { easeLinear } from 'd3-ease';

const d3 = {
  select,
  selectAll,
  scaleQuantile,
  zoom,
  geoMercator,
  geoPath,
  format,
  easeLinear
};

const getEvent = () => require("d3-selection").event;

export default class {
  constructor(props) {
    if (!props.ctx) {
      return new Error("No markup provided.");
    }
    this.container = d3.select(props.ctx);
    this.data = props.data || [];
    this.currentYear = props.firstYear
    this.props = props;
    this.geoJSON = dataGeoJson.default
    this.scaleColor
    this.dataMapSet = new Map();

    // Options
    this.minHeight = props.minHeight;
    this.maxWidth = props.maxWidth;
    this.mapProperty = props.mapProperty;
    this.mapPropertyInverted = props.mapPropertyInverted;
    this.geoTypeProp = props.geoTypeProp;
    this.geoItemProp = props.geoItemProp;
    this.geoItemFilter = props.geoItemFilter;
    this.scaleDomain = props.scaleDomain || '';
    this.colors = props.colors;
    this.titleLegend = props.titleLegend;
    this.onElementClick = props.onElementClick;
    this.timeLine = props.timeLine;
    this.selectedLanguage = document.documentElement.lang === 'es' ? 'nombre' : 'name'
    this.zoomText = document.documentElement.lang === 'es' ? 'Para hacer zoom presiona la tecla CTRL' : 'To zoom press the CTRL key'


    // Create main elements
    this.svg = this.container
      .append("svg")
      .attr('id', 'map-choroplet');
    this.g = this.svg.append("g");
    this.tooltip = this.container
      .append('div')
      .attr('class', 'tooltip-map tooltip-arrow')

    this.zoomMessage = this.container
      .append('div')
      .attr('class', 'zoom-message')
      .style('display', 'none')
      .append('p')
      .text(this.zoomText)

    if (this.timeLine) {

      this.svg
        .append('rect')
        .attr('x', 13)
        .attr('y', 27)
        .attr('width','100px')
        .attr('height','40px')
        .attr('fill', 'white')
        .attr('fill-opacity', '.75');

      this.yearTimer = this.svg
        .append('text')
        .attr('class', 'time-year')
        .attr('x', 20)
        .attr('y', 60)
    }

    if (this.data.length) {
      this.draw();
    }

    window.addEventListener("resize", this.draw.bind(this));
  }

  draw() {

    this.responsiveness()
    this.setElements()
    this.drawMap()

  }

  responsiveness() {
    this.isMobile = document.documentElement.clientWidth < 768;
    const widthMobile = window.innerWidth > 0 ? window.innerWidth : screen.width;

    this.margin = {
      top: this.props.marginTop !== undefined ? this.props.marginTop : 55,
      right: widthMobile <= 768 ? 105 : 200,
      bottom: this.props.marginBottom !== undefined ? this.props.marginBottom : 50,
      left: this.props.marginLeft !== undefined ? this.props.marginLeft : 50
    };

    this.aspectRatio = this.props.aspectRatio;
  }


  setElements() {

    // Dimensions
    this.width = +this.container.node().getBoundingClientRect().width
    this.height = +this.container.node().offsetHeight;

  }

  drawMap() {
    let filteredData = this.data
    if (this.timeLine) {
      filteredData = filteredData.filter(item => item[this.geoTypeProp] === this.geoItemFilter)
      filteredData = filteredData.filter(({ year }) => year === this.currentYear)
      this.yearTimer
        .text(this.currentYear)
    }

    const colors = this.mapPropertyInverted ? this.colors.slice().reverse() : this.colors
    this.scaleColor = d3.scaleQuantile()
      .domain(this.scaleDomain ? this.scaleDomain : Array.from(new Set(this.data.map((d) => d[this.mapProperty]))).filter(x => !!x))
      .range(colors);

    filteredData.forEach(d => {
      this.dataMapSet.set(d[this.geoItemProp], d[this.mapProperty]);
    })

    const self = this

    const zoom = d3.zoom()
      .filter(() => {
        if (getEvent().type === 'wheel') {
          d3.select('.zoom-message')
            .style('display', 'block')

          setTimeout(() => {
            d3.select('.zoom-message')
              .style('display', 'none')
          },1000)

          return getEvent().ctrlKey;
        }

        d3.select('.zoom-message')
          .style('display', 'none')
        return true;
      })
      .extent([[0, 0], [this.width, this.height]])
      .scaleExtent([1, 8])
      .on('zoom', function() {
        self.tooltip
          .style('opacity', 0)
        self.g
          .selectAll('path')
          .attr('transform', getEvent().transform);
      });

    this.svg.call(zoom);

    this.svgPattern = this.container.append("svg").attr("id", "d3svg")
      .attr("width", 120)
      .attr("height", 120);

    this.defs = this.svgPattern
      .append("defs");

    this.pattern = this.defs
      .append("pattern")
      .attr('id', 'no_data')
      .attr('width', 6)
      .attr('height', 6)
      .attr('patternUnits', "userSpaceOnUse")
      .attr('patternTransform', "rotate(45)")

    this.pattern
      .append("rect")
      .attr('width', 2)
      .attr('height', 12)
      .attr('transform', 'translate(0,0)')
      .attr('fill', '#E3E2E2')
      .attr('fill-opacity', 1)

    const countries = feature(this.geoJSON, this.geoJSON.objects.countries);
    const projection = d3.geoMercator().center([9.94, 14.70]).scale(220).translate([this.width / 2, this.height / 1.7]);
    const path = d3.geoPath().projection(projection);

    this.featureMap = this.g.selectAll('path')
      .data(countries.features)
      .enter()
      .append('path')
      .attr('class', d => {
        d[self.mapProperty] = self.dataMapSet.get(d.properties[self.selectedLanguage]);
        return d[self.mapProperty] ? `${d.properties[this.selectedLanguage]} countries` : `${d.properties[this.selectedLanguage]} countries-no-data`
      })
      .attr('d', path)
      .attr("fill", function(d) {
        d[self.mapProperty] = self.dataMapSet.get(d.properties[self.selectedLanguage]);
        return !d[self.mapProperty] ? 'url(#no_data)' : self.scaleColor(+d[self.mapProperty])
      })
      .on("mouseenter", function(d) {
        const nameCountry = d.properties[self.selectedLanguage]
        //Don't show tooltip if the country hasn't data
        const valueMap = d[self.mapProperty]
        if (!valueMap) return
        const element = d3.select(this)
        self.showTooltip(element, valueMap, nameCountry)
      })
      .on("mouseleave", function(d) {
        const valueMap = d[self.mapProperty]
        if (!valueMap) {
          self.tooltip
            .transition()
            .duration(400)
            .style('opacity', 0)
            .style('display', 'none')
        }
      })

    this.svg
      .attr("width", this.width)
      .attr("height", this.height)
      .on("mouseenter", function() {
        self.tooltip
          .transition()
          .duration(400)
          .style('opacity', 0)
          .style('display', 'none')
    })

    this.buildLegends()

  }

  buildLegends() {
    const self = this
    this.container
      .selectAll(".container-legends")
      .remove()

    const containerLegends = this.container
      .append('div')
      .attr('class', 'container-legends')

    containerLegends
      .append('div')
      .attr('class', 'legends legend-no-data')
      .html(() =>
        `<span class="rect-legend-no-data"></span>
        <p>No data</p>
        `
      )

    containerLegends.selectAll('.legends')
      .data(this.scaleColor.range().reverse())
      .enter()
      .append("div")
      .attr("class", "legends")
      .html((d) => {
        const extent = self.scaleColor.invertExtent(d);
        const format = d3.format(",.3f");
        const firstNumber = +(format(+extent[0]).replace(',', '.'));
        const secondNumber = +(format(+extent[1]).replace(',', '.'));
        return `<span class="rect-legend" style="background-color:${d}"></span>
        <p>${firstNumber.toFixed(2)} / ${secondNumber.toFixed(2)}</p>
        `
      })

    containerLegends.style('left', `${((this.width - (90 * this.scaleColor.range().length + 1)) / 2) - 15}px`)

  }

  showTooltip(element, mapProperty, name) {
    const value = +(mapProperty)

    const self = this

    const widthTooltip = this.tooltip.node().offsetWidth;
    const heightTooltip = this.tooltip.node().offsetHeight;
    let positionTooltip
    if(getEvent().layerX < widthTooltip) {
      positionTooltip = (widthTooltip / 2)
    } else if ((getEvent().layerX + widthTooltip) > this.width) {
      positionTooltip = this.width - (widthTooltip / 2)
    } else {
      positionTooltip = getEvent().layerX
    }

    this.tooltip
      .style('opacity', 0)
      .transition()
      .duration(100)
      .style('display', 'block')
      .style('opacity', 1)
      .style("left", `${positionTooltip - (widthTooltip / 2)}px`)
      .style("top", `${getEvent().layerY - (heightTooltip + 15)}px`)

    const labelDetail = document.documentElement.lang === 'es' ? 'Compare variables y países' : 'Compare indicators and countries'

    this.tooltip
      .html(
        `<span class="tooltip-title">${name}</span>
        <div class="tooltip-footer">
          <span class="tooltip-text">${self.currentYear}: <b>${value.toFixed(3)}</b></span>
          <br>
          <a data-country="${name}" class="tooltip-link">${labelDetail}</a>
        </div>
        `
      )

    d3.select('.tooltip-link')
      .on('click', function(d, event, i) {
        const [{ dataset: { country: countryName } }] = i
        self.onElementClick(countryName, self.mapProperty)
      })
  }

  updateMap(year) {
    this.tooltip
      .style('opacity', 0)
      .style('display', 'none')
    this.currentYear = year
    this.yearTimer
      .transition()
      .duration(300)
      .ease(d3.easeLinear)
      .text(year);

    this.updateChrolopletColors(true)
  }

  updateChrolopletColors(sliderTimeline = false) {
    const self = this
    this.tooltip
      .style('opacity', 0)
      .style('display', 'none')
    let filteredData = this.data.filter(({ year }) => year === this.currentYear)

    if(!sliderTimeline){
      const colors = this.mapPropertyInverted ? this.colors.slice().reverse() : this.colors
      this.scaleColor = d3.scaleQuantile()
        .domain(this.scaleDomain ? this.scaleDomain : Array.from(new Set(this.data.map((d) => d[this.mapProperty]))))
        .range(colors);

      this.buildLegends()
    }

    filteredData = filteredData.filter(item => item[this.geoTypeProp] === this.geoItemFilter)
    filteredData.forEach(d => {
      this.dataMapSet.set(d[this.geoItemProp], d[this.mapProperty]);
    })
    this.g.selectAll('.countries')
      .attr("fill", function(d) {
        d[self.mapProperty] = self.dataMapSet.get(d.properties[self.selectedLanguage]);
        return !d[self.mapProperty] ? 'url(#no_data)' : self.scaleColor(+d[self.mapProperty])
      })
      .on("mouseenter", function(d) {
        const nameCountry = d.properties[self.selectedLanguage]
        //Don't show tooltip if the country hasn't data
        const valueMap = self.dataMapSet.get(d.properties[self.selectedLanguage])
        if (!valueMap) return
        const element = d3.select(this)
        self.showTooltip(element, valueMap, nameCountry)
      })
      .on("mouseleave", function(d) {
        const valueMap = self.dataMapSet.get(d.properties[self.selectedLanguage])
        if (!valueMap) {
          self.tooltip
            .transition()
            .duration(400)
            .style('opacity', 0)
            .style('display', 'none')
        }
      })
  }
}
