<template>
  <div>
    <div class="row row--theme">
      <Selector
        :options="varXNames"
        :default="defaultVarX"
        open-direction="bottom"
        @handleSelect="handleSelector($event, 'x')"
        v-bind="groupProps"
      ></Selector>
      <Selector
        v-if="range && range.length"
        :options="rangeSorted"
        :default="defaultRange"
        open-direction="bottom"
        @handleSelect="handleSelector"
      ></Selector>
    </div>

    <Loader :loading="loading" :empty="empty" :info="currentRange" />
    <div id="spectrum-chart" ref="chart">
      <Tooltip ref="tooltip" />
    </div>

    <template v-if="data && data.length">
      <SelectorButton
        :options="aggregationsAvailable"
        :placeholder="properties.btnPlaceholder"
        @handleSelect="handleSelectorButton"
      ></SelectorButton>
    </template>
  </div>
</template>

<script>
import { VisualizationMixin } from "../../assets/mixins";

import Espectro from "../../assets/charts/Espectro";
import Selector from "../selectors/Selector";
import SelectorButton from "../selectors/SelectorButton";
import Loader from "../Loader";
import Tooltip from "../Tooltip";

export default {
  name: "Espectro",
  components: {
    Selector,
    SelectorButton,
    Loader,
    Tooltip
  },
  mixins: [VisualizationMixin],
  data() {
    return {
      data: [],
      chart: null
    };
  },
  props: {
    properties: Object
  },
  computed: {
    aggregationsAvailable() {
      return [
        ...new Set(
          this.data
            .filter(d => d.hidden)
            .map(d => d[this.properties.aggregationProp])
        )
      ];
    }
  },
  mounted() {
    const props = {
      ...this.properties,
      ctx: this.$refs.chart,
      data: this.data,
      onElementClick: this.handleClick,
      tooltipContainer: this.$refs.tooltip.$refs.tooltip
    };

    this.currentVarY = this.properties.baseDataset;
    this.chart = new Espectro(props);
  },
  methods: {
    setChartData() {
      if (this.data.length && this.properties.aggregationProp) {
        this.setSample(this.properties.sampleSize);
        this.chart.data = this.data.filter(d => !d.hidden);
        this.render();
      }
    },
    setSample(size = 3) {
      const categories = [
        ...new Set(this.data.map(d => d[this.properties.aggregationProp]))
      ];
      const sample = categories.slice(0, size);

      this.data = this.data.map(d =>
        !sample.includes(d[this.properties.aggregationProp])
          ? { ...d, hidden: true }
          : d
      );
    },
    handleSelectorButton(element) {
      if (element) {
        this.data = this.data.map(d =>
          d[this.properties.aggregationProp] === element
            ? { ...d, hidden: false }
            : d
        );

        this.setChartData();
      }
    },
    handleClick(element) {
      if (element) {
        this.data = this.data.map(d =>
          d[this.properties.aggregationProp] === element
            ? { ...d, hidden: true }
            : d
        );

        this.setChartData();
      }
    },
    async handleSelector(...args) {
      const [event, param] = args;

      if (param === this.$options.series.x) {
        const x = this.varX.find(d => d.name === event);

        if (x) {
          this.handleVarX(x);
        }
      }

      if (this.range.includes(event)) {
        this.handleRange(event);
      }

      if (this.currentRange && this.currentVarX && this.currentVarY) {
        await this.fetchData();
        this.setChartData();
      }
    }
  }
};
</script>

<style>
#spectrum-chart {
  width: 100%;
  position: relative;
}
</style>
