<template>
  <button class="chart-type__button" @click="displayChart" :class="{ 'active': active }">
    <v-icon :name="`chart-${chart.icon}`"></v-icon>
    {{ chart.title }}
  </button>
</template>

<script>
import "vue-awesome/icons/chart-bar";
import "vue-awesome/icons/chart-line";
import "vue-awesome/icons/chart-area";
import Icon from "vue-awesome/components/Icon";

export default {
  components: {
    "v-icon": Icon
  },
  props: {
    chart: Object,
    default: Boolean,
    active: Boolean
  },
  mounted() {
    if (this.default) {
      this.displayChart();
    }
  },
  methods: {
    displayChart() {
      this.$emit("handleClick", this.chart.id);
    }
  }
};
</script>

<style>
.chart-type__button {
  background: #fff;
  border: 1px solid #f8b207;
  border-radius: 3px;
  text-transform: uppercase;
  padding: 4px 12px;
  color: #f8b207;
  font: inherit;
  line-height: normal;
  overflow: visible;
  -webkit-appearance: button;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin: 2px;
  margin: var(--items-gap, 2px);
}

.chart-type__button.active,
.chart-type__button:hover {
  background: #f8b207;
  color: #fff;
}

.chart-type__button svg {
  margin-right: 10px;
}

.chart-type__button:only-child {
  display: none;
}
</style>

