<template>
  <Multiselect
    v-model="value"
    :options="options"
    :multiple="multiple"
    :open-direction="openDirection"
    :placeholder="placeholder"
    :group-values="groupValues"
    :group-label="groupLabel"
    :group-select="false"
    :max-height="maxHeight"
    :track-by="trackBy"
    :label="label"
    :key="lang"
    select-label
    selected-label
    deselect-label
    @input="handleSelect"
  >
  </Multiselect>
</template>

<script>
import Multiselect from "vue-multiselect";

export default {
  name: "Selector",
  props: {
    multiple: Boolean,
    options: Array,
    default: [String, Number, Array],
    openDirection: String,
    placeholder: String,
    groupLabel: String,
    groupValues: String,
    className: String,
    maxHeight: Number,
    label: String,
    trackBy: String,
    lang: String
  },
  components: { Multiselect },
  data() {
    return {
      value: null
    };
  },
  mounted() {
    if (this.default) {
      this.value = this.default;
      this.handleSelect(this.value);
    }
  },
  methods: {
    handleSelect(value) {
      this.$emit("handleSelect", value);
    }
  },
  watch: {
    default(n) {
      const evaluationClause = this.multiple
        ? JSON.stringify(this.value) !== JSON.stringify(n)
        : this.value !== n;

      if (evaluationClause) {
        this.value = n;
        this.handleSelect(this.value);
      }
    }
  }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
  .multiselect--keep-placeholder .multiselect__input {
    width: 100% !important;
    position: relative !important;
    padding: 0 !important;
  }

  .multiselect__input {
    cursor: pointer;
    width: 100% !important;
    margin: 0 !important;
  }

  .multiselect__option{
    padding: .5rem 1.5rem !important;
    min-height: auto !important;
  }

  .multiselect__option.multiselect__option--group {
    padding: .5rem .75rem !important;
    background: transparent !important;
    font-weight: bold !important;
    color: #111 !important;
  }

  .multiselect__content-wrapper{
    max-height: 300px !important;
    box-shadow: 0 0 13px 0 rgba(0,0,0,.15);
    background-color: #fff;
    border: 1px solid #cacaca;
    border-radius: 2px;
  }

  .multiselect__placeholder,
  .multiselect__tags-wrap {
    display: none;
  }

  .multiselect__tags{
    border-color: #9B9A9B;
    min-height: auto !important;
    padding: 10px 40px 10px 14px !important;
  }

  .multiselect__option{
    white-space: normal !important;
    line-height: 1.25;
  }

</style>
