<template>
  <div class="tooltip" ref="tooltip"></div>
</template>

<script>
export default {
  name: "Tooltip"
};
</script>

<style>
.tooltip {
  background: #fff;
  border: 1px solid #cacaca;
  box-shadow: 0 0 13px 0 rgba(0, 0, 0, .15);
  font-size: 14px;
  color: #4a4a4a;
  position: relative;
  border-radius: 2px;
  transform: translate(-50%, -100%);
  opacity: 0;
  transition: opacity .3s;
  width: -moz-max-content;
  width: -webkit-max-content;
  width: max-content;
  pointer-events: none;
}

.tooltip::after,
.tooltip::before {
  top: 100%;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.tooltip::after {
  border-color: rgba(255, 255, 255, 0);
  border-top-color: #fff;
  border-width: 10px;
  margin-left: -10px;
}
.tooltip::before {
  border-color: rgba(202, 202, 202, 0);
  border-top-color: #cacaca;
  border-width: 11px;
  margin-left: -11px;
}

@media only screen and (max-width: 525px) {

  .tooltip {
    left: 50% !important;
  }

}
</style>
