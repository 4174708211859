<template>
  <div>
    <div class="chart-wrapper">
      <p v-if="properties.bspline" class="text-bspline">{{ $t('bsplinePlaceholder') }} <a :href="$t('bsplineLink')">B-spline</a></p>
      <div
        id="line-chart"
        ref="chart"
      >
        <div
          id="line-selector-right"
          class="row row--theme"
          :class="properties.pivotTableSelector ? 'multiselect-multiple selector-right' : 'selector-right-one-select'"
        >
          <div v-if="properties.pivotTableSelector" class="selector-wrapper-line">
            <PivotTableSelector
              v-if="properties.pivotTableSelector"
              class="multiselect--min-width multiselect--keep-placeholder multiselect-pivot"
              :options="varXNamesPivotTable"
              :multiple="true"
              :default="pivotSelectedValue"
              :value="pivotSelectedValue"
              :max-height="maxHeight"
              :placeholder="$t('btnPivotPlaceholder')"
              @handleSelectPivotTable="handleSelectorPivotTable"
              v-bind="groupProps"
              :properties="properties"
            />
            <IconInfo
              v-if="properties.pivotTableSelector"
              v-tooltip="$t('iconInfoCountryPlaceholder')
            "/>
          </div>
          <div :class="properties.pivotTableSelector ? 'selector-wrapper-line' : 'selector-wrapper-full'">
            <Selector
              class="multiselect--keep-placeholder multiselect--right"
              :options="varXNames"
              :multiple="multipleSelector"
              :default="defaultVarX"
              :value="defaultVarX"
              :max-height="maxHeight"
              :placeholder="placeholderValue"
              @handleSelect="handleSelector($event, 'x')"
              v-bind="groupProps"
              :properties="properties"
              :language="language"
            />
            <IconInfo v-tooltip="$t('iconInfoSeriePlaceholder')"/>

          </div>

        </div>
        <Legend
          :items="currentVarX"
          :color="aggregationColor"
          @onClick="handleLegendClick"
          @onMouseenter="handleLegendMouseenter"
          @onMouseout="handleLegendMouseout"
          @onCloseClick="handleLegendCloseClick"
        />
        <Tooltip ref="tooltip" />
        <Brush
          v-if="brushData"
          :properties="properties"
          :data="data"
          @callBrushed="handleBrush"
        />
      </div>
    </div>
  </div>
</template>
<i18n src="../../locales/lineComponent.json"></i18n>
<script>
import { VisualizationMixin } from "../../assets/mixins";

import Line from "../../assets/charts/Line";
import Selector from "../selectors/Selector";
import PivotTableSelector from "../selectors/PivotTableSelector";
import Legend from "../selectors/LegendRight";
import Brush from "../selectors/Brush";
import Tooltip from "../Tooltip";
import IconInfo from "./IconInfo";

export default {
  name: "Linechart",
  components: {
    Selector,
    PivotTableSelector,
    Tooltip,
    Legend,
    Brush,
    IconInfo
  },
  mixins: [VisualizationMixin],
  data() {
    return {
      data: [],
      chart: null,
      cacheMap: null,
      brushRange: Array,
      brushData: false,
      brushRangeUser: false,
      pivotTableIsActive: false,
      multipleSelector: true,
      varXNamesPivotTable: [],
      filterPivotX: null,
      countriesSelected: [],
      pivotTableData: [],
      pivotSelectedValue: this.pivotDefaultValue,
      placeholderValue: ''
    };
  },
  props: {
    properties: Object,
    container: {
      type: Boolean,
      default: false
    },
    pivotDefaultValue: {
      type: String,
      default: ''
    },
    pivotTableValue: {
      type: String,
      default: ''
    },
    pivotTableShow: {
      type: Boolean,
      default: false
    },
    language: {
      type: String,
      default: ''
    }
  },
  async mounted() {
    this.$i18n.locale = this.language
    const defaultSizes = {
      minHeight: 320,
      maxWidth: 1440,
      aspectRatio: 16 / 9
    };

    const props = {
      ...defaultSizes,
      ...this.properties,
      ctx: this.$refs.chart,
      data: this.data,
      tooltipContainer: this.$refs.tooltip.$refs.tooltip,
    };

    ({ brush: this.brushRange } = this.properties)


    this.chart = new Line(props);

    if (this.brushRange !== undefined) {
      this.dateMin = new Date(this.brushRange[0], 0);
      this.dateMax = new Date(this.brushRange[1], 0);

      const rangeCustomConfig = [this.dateMin, this.dateMax]
      this.$root.$emit('updateLineValuesBrush', rangeCustomConfig);
    }

    this.$root.$on('changeScaleLogNatural', this.handleScaleLogNatural)
    this.$root.$on('changeScaleLog', this.handleScaleLog)
    this.$root.$on('changeScaleLineal', this.handleScaleLineal)
    this.$root.$on('changeChartStack', this.handleChartStack)
    this.$root.$on('changeChartLine', this.handleChartLine)
    this.placeholderValue = this.$t('btnPlaceholder')
  },
  methods: {
    async setChartData() {
      this.chart.data = await this.setHiddenStatus();

      const brushMin = this.rangeCustom ? this.rangeCustom[0].setFullYear(this.rangeCustom[0].getFullYear() - 1) : this.dateMin
      const brushMax = this.rangeCustom ? this.rangeCustom[1].setFullYear(this.rangeCustom[1].getFullYear() + 1) : this.dateMax

      this.chart.data = this.chart.data.filter(d => d.time > brushMin && d.time < brushMax)

      this.render();
      this.brushData = true
      this.checkNegativeValues()
    },
    async setHiddenStatus() {

      const currentSeries = this.currentVarX.map(d => d.id);

      if (!this.cacheMap || this.pivotTableIsActive) {
        this.cacheMap = new Map();

        const aggs = [
          ...new Set(this.data.map(d => d[this.properties.aggregationProp]))
        ];
        aggs.map(agg => this.cacheMap.set(agg, currentSeries.includes(agg)));
      } else {
        const keys = this.cacheMap.keys();

        for (const iterator of keys) {
          this.cacheMap.set(iterator, currentSeries.includes(iterator));
        }
      }

      if (this.pivotTableShow && !this.data.length && !this.pivotTableIsActive) {
        const data = await this.fetchData()
        this.pivotTableData = data
        this.data = this.pivotSelectedValue !== '' ? data.groups.filter(d => d[this.pivotTableValue] === this.pivotSelectedValue) : data.groups
        this.cacheMap = new Map();

        const aggs = [
          ...new Set(this.data.map(d => d[this.properties.aggregationProp]))
        ];
        aggs.map(agg => this.cacheMap.set(agg, currentSeries.includes(agg)));
      }

      const data = [];
      this.data.forEach(line => {
        if (this.cacheMap.get(line[this.properties.aggregationProp])) {
          data.push(line);
        }
      });

      return data;

    },
    async handleSelector(...args) {
      const [event, param] = args;

      if(this.pivotTableShow) {
        this.chart.aggregationProp = 'serie'
      }
      if(!this.multipleSelector && this.countriesSelected.length >= 2) {
        const { groups: data } = this.pivotTableData
        let serieSelect = data.map(d => d.name)
        serieSelect = [...new Set(serieSelect.filter(d => d === event))]
        let filteredData = data.filter(d => serieSelect.includes(d.name))
        const countriesIndicators = this.countriesSelected.map(element => ({ id: element, name: element }));
        this.setDataPivotTable(countriesIndicators, filteredData)
        this.placeholderValue = serieSelect[0]
        return
      }

      if (param === this.$options.series.x) {
        const x = this.varX.filter(d => event.includes(d.name));

        if (x) {
          this.handleMultipleVarX(x);

          // Avoid unnecessary API calls
          if (!this.data.length || !this.properties.cache) {

            await this.fetchData();
          }

          this.setChartData();
        }
      }
    },
    async handleSelectorPivotTable(...args) {
      this.chart.aggregationProp = this.chart.aggregationProp !== this.pivotTableValue ? this.pivotTableValue : this.chart.aggregationProp
      this.pivotTableIsActive = true
      const { groups: data } = this.pivotTableData
      this.countriesSelected = args.flat()
      this.countriesSelected = [...new Set(this.countriesSelected)]
      this.multipleSelector = this.countriesSelected.length >= 2 ? false : true

      let serieSelect = this.chart.data.map(d => d.name)
      serieSelect = [...new Set(serieSelect)]
      serieSelect = serieSelect[0]
      this.defaultVarX = serieSelect
      let filteredData = data.filter(d => serieSelect.includes(d.name))
      filteredData = filteredData.filter(element => this.countriesSelected.includes(element[this.pivotTableValue]))
      let countriesOrdered = filteredData.map(d => d[this.pivotTableValue])
      countriesOrdered = [...new Set(countriesOrdered)]
      const countriesIndicators = countriesOrdered.map(element => ({ id: element, name: element }));
      this.setDataPivotTable(countriesIndicators, filteredData)
      this.placeholderValue = this.countriesSelected.length > 1 ? serieSelect : this.properties.btnPlaceholder
      if(this.countriesSelected.length === 1) {
        this.$emit('updateBreadCrumb', this.countriesSelected[0])
      }
    },
    setDataPivotTable(values, data) {
      this.chart.aggregationProp = this.pivotTableValue
      this.cacheMap = new Map();

      const selectedValues = values.map(d => d.id)

      const aggs = [
        ...new Set(data.map(d => d[this.chart.aggregationProp]))
      ];
      aggs.map(agg => this.cacheMap.set(agg, selectedValues.includes(agg)));

      const newData = [];
      data.forEach(line => {
        if (this.cacheMap.get(line[this.chart.aggregationProp])) {
          newData.push(line);
        }
      });

      this.chart.data = newData

      this.handleMultipleVarX(values)
      this.render();
    },
    handleMultipleVarX(indicators) {
      if (JSON.stringify(this.currentVarX) !== JSON.stringify(indicators)) {
        this.currentVarX = indicators;
        this.$root.$emit('updateValuesVarX', this.currentVarX);
      }
    },
    aggregationColor(element) {
      const { palette = this.chart.palette } = this.properties;
      const i = this.currentVarX.findIndex(d => d.name === element);

      return palette[i % palette.length];
    },
    handleLegendClick(element, status) {
      this.chart.onClick(
        this.currentVarX.findIndex(d => d.id === element.id),
        status
      );
    },
    handleBrush(valueFromBrush) {
      this.rangeCustom = valueFromBrush;
      if(this.pivotTableIsActive) {

        const brushMin = this.rangeCustom[0].setFullYear(this.rangeCustom[0].getFullYear() - 1);
        const brushMax = this.rangeCustom[1].setFullYear(this.rangeCustom[1].getFullYear() + 1);
        let serieSelect = this.chart.data.map(d => d.name)
        serieSelect = [...new Set(serieSelect)]
        const filteredData = this.pivotTableData.groups.filter(d => serieSelect.includes(d.name))
        this.chart.data = filteredData
          .filter(element => this.countriesSelected.includes(element[this.pivotTableValue]))
          .filter(d => d.time > brushMin && d.time < brushMax)
        this.render()
      } else {
        this.setChartData();
      }
      this.$root.$emit('updateLineValuesBrush', this.rangeCustom);
    },
    handleLegendMouseenter(element) {
      this.chart.onMouseenter(
        this.currentVarX.findIndex(d => d.id === element.id)
      );
    },
    handleLegendMouseout(element) {
      this.chart.onMouseout(
        this.currentVarX.findIndex(d => d.id === element.id)
      );
    },
    handleLegendCloseClick(element) {
      if (this.currentVarX.some(d => d.id === element.id)) {
        const i = this.currentVarX.findIndex(d => d.id === element.id);

        if (this.chart.hasOwnProperty("currentHighlights")) {
          delete this.chart.currentHighlights[i];
        }

        this.currentVarX.splice(i, 1);

        if (!this.multipleSelector) {
          this.handleLegendClosePivot(i)
        } else {
          this.handleLegendCloseMultiple(i)
        }
        this.$root.$emit('updateValuesVarX', this.currentVarX);
      }
    },
    handleLegendClosePivot() {
      this.countriesSelected = this.currentVarX.map(d => d.name);
      this.countriesSelected = [...new Set(this.countriesSelected)]

      this.pivotSelectedValue = this.countriesSelected
      this.handleSelectorPivotTable(this.countriesSelected)
    },
    handleLegendCloseMultiple() {
      this.defaultVarX = this.currentVarX.map(d => d.name);

      this.setChartData();
    },
    handleScaleLog() {
      this.chart.changeScaleLog()
    },
    handleScaleLogNatural() {
      this.chart.changeScaleLogNatural()
    },
    handleScaleLineal() {
      this.chart.changeScaleLinear()
    },
    handleChartStack() {
      this.properties.type = 'Stacked'
      this.chart.props.typeChart = 'Stacked'
      this.chart.handleStack()
    },
    handleChartLine() {
      this.properties.type = 'Line'
      this.chart.props.typeChart = 'Line'
      this.chart.handleLines()
    },
    checkNegativeValues() {
      const dataNegative = this.chart.data
      let negative = false
      let negativeValuesObject = dataNegative.map(d => ({ value: d.value }));

      negativeValuesObject.forEach(a => {
        a = Object.keys(a).map(key => a[key]);

        a.forEach(d => {
          if (d < 0) {
            negative = true
          }
        });

      });
      if (negative) {
        this.$root.$emit('updateNegativeValues', true);
      }
    }
  }
};
</script>
<style>
#line-chart {
  width: 100%;
  position: relative;
  border-top: 1px solid rgba(151, 151, 151, .2);
  border-bottom: 1px solid rgba(151, 151, 151, .2);
}

.chart-wrapper {
  height: auto;
  position: relative;
}

#image-legends {
  width: 95%;
}

.title-graph {
  border-bottom: 1px solid grey;
  margin-left: .5rem;
  font-family: inherit;
}

.text-graph {
  font-size: 14px;
  font-family: inherit;
  width: 95%;
  margin-top: -15px;
  margin-left: 1rem;
  padding-bottom: 1rem;
}

.tooltip-container {
  padding: .5rem;
  position: absolute;
}

.tooltip-line-date {
  font-size: 1rem;
  font-weight: bold;
  color: #000;
  margin-top: 0;
  margin-bottom: .25rem;
}

.tooltip-element {
  border-top: 1px solid #ccc;
  padding-bottom: .25rem;
  font-size: 0;
  max-width: 350px;
}

.tooltip-element-left,
.tooltip-element-right,
.tooltip-element-top {
  width: 60%;
  display: inline-block;
  font-size: 14px;
  vertical-align: top;
  padding-top: .25rem;
  box-sizing: border-box;
}

.tooltip-element-top {
  width: 100%;
}

.tooltip-element-left {
  color: #ccc;
  padding-right: .25rem;
}

.tooltip-element-right {
  color: #000;
  width: 40%;
}

.selector-right,
.selector-right-one-select {
  width: max-content;
  display: flex;
  margin-left: auto;
  background-color: transparent;
  text-decoration: underline;
}

.selector-right .multiselect--keep-placeholder .multiselect__input {
  min-width: max-content;
  text-align: left;
}

.selector-right .multiselect--keep-placeholder .multiselect__input:before {
  content: '*';
  height: 1px;
  width: 20px;
  background-color: grey;
}

.multiselect__input::placeholder {
  color: #006561 !important;
  opacity: 1;
}

.multiselect__input:-ms-input-placeholder {
  color: #006561 !important;
}

.multiselect__input::-ms-input-placeholder {
  color: #006561 !important;
}

.selector-right .multiselect__select::before {
  display: none;
}

.multiselect__option--highlight{
  background-color: #006561 !important;
}

.y.axis .domain {
  stroke: none;
}

.multiselect__single {
  display: none;
}

.multiselect-pivot .multiselect__content-wrapper {
  max-height: 250px !important;
}

.multiselect-pivot {
  display: inline-block;
}

.line-chart__label_serie {
  font-size: 14px;
  color: #000;
  font-weight: bold;
  display: block;
  text-align: right;
  text-decoration: none;
  padding-right: 1.75rem;
  position: absolute;
  top: -20px;
  right: 0%;
}

.text-bspline {
  font-size: 12px;
  color: #000;
}

.info-icon {
  display: inline-block;
  vertical-align: middle;
}

.selector-wrapper-line,
.selector-wrapper-full{
  width: 50%;
  max-width: 700px;
  display: flex;
  margin: 1rem 0;
  margin-right: .5rem;
  align-items: center;
  gap: .5rem;
}

.selector-wrapper-full{
  width: 100%;
}

.selector-wrapper-line:not(:last-child){
  margin-right: 1rem;
}

.multiselect__select{
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23999' d='M11.9997 13.1714L16.9495 8.22168L18.3637 9.63589L11.9997 15.9999L5.63574 9.63589L7.04996 8.22168L11.9997 13.1714Z'%3E%3C/path%3E%3C/svg%3E");
  top: 8%;
  padding-right: 0 !important;
}

.container-legends{
  position: absolute;
  top: 95%;
  left: 0%;
  width: max-content;
  display: flex;
  align-items: center;
  gap: 8px;
  background: #f9f9f9;
  padding: .5rem 1rem;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.rect-legend-no-data{
  width: 12px;
  height: 12px;
  border-radius: 4px;
  border: 1px solid #ccc;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' id='d3svg' width='120' height='120'%3E%3Cdefs%3E%3Cpattern id='no_data' width='6' height='6' patternUnits='userSpaceOnUse' patternTransform='rotate(45)'%3E%3Crect width='2' height='12' transform='translate(0,0)' fill='%23E3E2E2' fill-opacity='1'%3E%3C/rect%3E%3C/pattern%3E%3C/defs%3E%3Crect width='100%25' height='100%25' fill='url(%23no_data)'/%3E%3C/svg%3E");
}

.container-legends p{
  font-size: 10px;
  margin: 0;
}

.rect-legend{
  width: 12px;
  height: 12px;
  border-radius: 4px;
  border: 1px solid #9B9A9B;
}

.legends{
  display: flex;
  align-items: center;
  gap: 4px;
}

@media only screen and (max-width: 560px) {

  .selector-right.multiselect-multiple .multiselect--min-width,
  .selector-wrapper-line,
  .selector-wrapper-full {
    width: 95%;
  }

  .selector-right.multiselect-multiple {
    flex-direction: column;
    right: initial;
    max-width: 100% !important;
    min-width: 100% !important;
  }

}

</style>
