import { render, staticRenderFns } from "./BreadCrumb.vue?vue&type=template&id=18f8e6a2&"
import script from "./BreadCrumb.vue?vue&type=script&lang=js&"
export * from "./BreadCrumb.vue?vue&type=script&lang=js&"
import style0 from "./BreadCrumb.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "../../locales/breadCrumb.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Ftmp%2Fbuild%2Fsrc%2Fcomponents%2Fselectors%2FBreadCrumb.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports