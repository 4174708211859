<template>
  <div class="share__buttons" v-clickoutside="closeModal">
    <span @click.self="isActive = !isActive" title="Ver opciones">
      <v-icon name="ellipsis-v" scale="0.75"></v-icon>
      <transition name="modal-fade">
        <ActionModal
          v-bind:class="{ active: isActive}"
          :id="id"
          :share-url="baseurl"
          :export-markup="exportMarkup"
          :export-data="exportData"
          :export-data-filter="exportDataFilter"
          :config="config"
          :language="language"
          @close-modal="isActive = false"
          @change-language="handleLocale"
        />
      </transition>
    </span>
    <span @click="openLink('facebook')">
      <v-icon name="brands/facebook-f" scale="0.75"></v-icon>
    </span>
    <span @click="openLink('twitter')">
      <v-icon name="brands/twitter" scale="0.75"></v-icon>
    </span>
  </div>
</template>

<script>
import "vue-awesome/icons/ellipsis-v";
import "vue-awesome/icons/brands/facebook-f";
import "vue-awesome/icons/brands/twitter";
import Icon from "vue-awesome/components/Icon";
import ActionModal from "./ActionModal";
import { EventBus } from "../assets/bus";

export default {
  components: {
    "v-icon": Icon,
    ActionModal
  },
  props: {
    id: {
      type: String,
      default: ""
    },
    language: {
      type: String,
      default: ""
    },
    config: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      baseurl: `${document.location.href.split("#")[0]}#${this.id}`,
      isActive: false,
      exportMarkup: null,
      exportData: null,
      exportDataFilter: null
    };
  },
  created() {
    EventBus.$on(`chart-${this.id}--markup`, this.handleExportMarkup);
    EventBus.$on(`chart-${this.id}--data`, this.handleExportData);
    EventBus.$on(`chart-${this.id}--data-filter`, this.handleExportDataFilter);
  },
  methods: {
    handleLocale(language) {
      this.$emit("change-language-app", language);
    },
    openLink(type) {
      const url =
        type === "facebook"
          ? `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
              this.baseurl
            )}`
          : type === "twitter"
          ? `https://twitter.com/intent/tweet?url=${encodeURIComponent(
              this.baseurl
            )}`
          : this.baseurl;

      window.open(url);
    },
    handleExportMarkup(cb) {
      this.exportMarkup = cb;
    },
    handleExportData(cb) {
      this.exportData = cb;
    },
    handleExportDataFilter(cb) {
      this.exportDataFilter = cb;
    },
    closeModal() {
      this.isActive = false
    },
  },
  beforeDestroy() {
    EventBus.$off(`chart-${this.id}--markup`, this.handleExportMarkup);
    EventBus.$off(`chart-${this.id}--data`, this.handleExportData);
    EventBus.$off(`chart-${this.id}--data-filter`, this.handleExportData);
  },
  directives: {
    clickoutside: {
      bind: function(el, binding, vnode) {
        el.clickOutsideEvent = function(event) {
          // here I check that click was outside the el and his childrens
          if (!(el == event.target || el.contains(event.target))) {
            // and if it did, call method provided in attribute value
            vnode.context[binding.expression](event);
          }
        };
        document.body.addEventListener('click', el.clickOutsideEvent)
      },
      unbind: function(el) {
        document.body.removeEventListener('click', el.clickOutsideEvent)
      },
      stopProp(event) { event.stopPropagation() }
    }
  }
};
</script>

<style>
.share__buttons {
  margin-left: auto;
  display: inline-block;
  width: 30%;
  text-align: right;
}

.share__buttons > span {
  background-color: #01909e;
  height: 28px;
  width: 28px;
  border-radius: 100%;
  display: inline-flex;
  color: #fff;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
}

.share__buttons > span:not(:first-child):hover {
  filter: brightness(0.9);
}

.share__buttons > span:not(:first-child) {
  margin-left: 9px;
}

.share__buttons > span > :first-child {
  pointer-events: none;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.5s;
}
.modal-fade-enter,
.modal-fade-leave-to {
  opacity: 0;
}

.hide-brush {
  visibility: hidden;
}

.active {
  visibility: visible;
  opacity: 1;
  transition: all .4s ease-in-out;
  transition-property: opacity, visibility;
}
</style>
