import * as d3 from "d3";

export default class {
  constructor(props) {
    if (!props.ctx) {
      return new Error("No markup provided.");
    }

    this.container = d3.select(props.ctx);
    this.data = props.data || [];
    this.props = props;

    // Options
    this.maxWidth = props.maxWidth || 1440;
    this.blockHeight = props.blockHeight !== undefined ? props.blockHeight : 120;
    this.blockGap = props.blockGap !== undefined ? props.blockGap : 10;
    this.xAxisProp = props.xAxisProp;
    this.yAxisProp = props.yAxisProp;
    this.aggregationProp = props.aggregationProp;
    this.transitionTime = props.transitionTime !== undefined ? props.transitionTime : 1000;
    this.legendLabels = props.legendLabels || ["Más", "Menos"];
    this.averageLabel = props.averageLabel !== undefined ? props.averageLabel : "Media";
    this.medianLabel = props.medianLabel !== undefined ? props.medianLabel : "Mediana";
    this.onElementClick = props.onElementClick;
    this.tooltip = props.tooltip || this.defaultTooltip;
    this.xScaleFunction = props.xScaleFunction || (d3 => d3.scaleSqrt());
    this.yScaleFunction = props.yScaleFunction || (d3 => d3.scaleSqrt());
    this.styles = this.defaultStyles(props.styles);
    this.tooltipContainer = props.tooltipContainer ? d3.select(props.tooltipContainer) : false;

    // Main elements
    this.svg = this.container.append("svg");
    this.setDefs();

    if (this.data.length) {
      this.draw();
    }

    window.addEventListener("resize", this.draw.bind(this));
  }

  draw() {
    if (this.data.length) {
      this.responsiveness();
      this.setElements();
      this.handleBlocks();
    }
  }

  responsiveness() {
    this.isMobile = document.documentElement.clientWidth < 768;
    this.margin = {
      top: this.props.marginTop !== undefined ? this.props.marginTop : 15,
      right: this.props.marginRight !== undefined ? this.props.marginRight : 5,
      bottom: this.props.marginBottom !== undefined ? this.props.marginBottom : 15,
      left: this.props.marginLeft !== undefined ? this.props.marginLeft : 0
    };
  }

  setElements() {
    this.categories = [...new Set(this.data.map(d => d[this.aggregationProp]))].sort();

    // Dimensions
    this.width = Math.min(this.maxWidth, +this.container.node().getBoundingClientRect().width - this.margin.left - this.margin.right);
    this.height = this.categories.length * (this.blockHeight + this.blockGap) + 2 * this.margin.top - this.margin.top - this.margin.bottom;
    this.labelWidth = this.isMobile ? this.width / 3 : this.width / 5;

    // Positions
    this.svg
      .attr("width", this.width + this.margin.left + this.margin.right)
      .attr("height", this.height + this.margin.top + this.margin.bottom)
      .attr("transform", `translate(${this.margin.left},${this.margin.top})`);

    // Legend
    this.handleLegend();

    // Scales
    this.x = this.xScaleFunction(d3)
      .range([this.labelWidth, this.width])
      .domain(d3.extent(this.data, d => d[this.xAxisProp]))
      .nice();

    this.y0 = d3
      .scaleBand()
      .range([this.height, 0])
      .domain(this.categories);

    this.y1 = this.yScaleFunction(d3)
      .range([0, this.blockHeight])
      .domain(d3.extent(this.data, d => d[this.yAxisProp]));
  }

  setDefs() {
    const defs = this.svg.append("defs");

    defs
      .append("marker")
      .attr("id", "arrowhead-end")
      .attr("markerHeight", 5)
      .attr("markerWidth", 5)
      .attr("markerUnits", "strokeWidth")
      .attr("orient", "auto")
      .attr("refX", 0)
      .attr("refY", 0)
      .attr("viewBox", "-5 -5 10 10")
      .append("path")
      .attr("d", "M 0,0 m -5,-5 L 5,0 L -5,5 Z");

    defs
      .append("marker")
      .attr("id", "arrowhead-start")
      .attr("markerHeight", 5)
      .attr("markerWidth", 5)
      .attr("markerUnits", "strokeWidth")
      .attr("orient", "auto")
      .attr("refX", 0)
      .attr("refY", 0)
      .attr("viewBox", "-5 -5 10 10")
      .append("path")
      .attr("d", "M 10,0 m -5,-5 L -5,0 L 5,5 Z");
  }

  handleBlocks() {
    const dataByGroups = d3
      .nest()
      .key(d => d[this.aggregationProp])
      .sortKeys(d3.ascending)
      .entries(this.data);

    // Groups
    const groups = this.svg.selectAll(".agg").data(dataByGroups);

    groups.exit().remove();

    const groupsEnter = groups
      .enter()
      .append("g")
      .attr("class", (d, i) => `agg --${i}`)
      .attr(
        "transform",
        (d, i) => `translate(0,${(this.blockHeight + this.blockGap / 2) * i + (this.blockGap / 2) * (i + 1) + this.margin.top * 2})`
      );

    const groupsMerged = groups.merge(groupsEnter);

    // Titles
    this.handleTitleTexts(groupsMerged);
    this.handleTitleRects(groupsMerged);

    // Decorators
    this.handleDecorators(groupsMerged);

    // Inner rects
    this.handleInnerRects(groupsMerged);

    // Maths
    this.handleMathRects(groupsMerged);
    this.handleMathTexts(groupsMerged);

    // Remove all unnecessary labels
    this.svg.selectAll("g:not(.--0) text.math").remove();
  }

  handleLegend() {
    const legend = this.svg.selectAll(`g.${this.styles.legend.cssClass}`).data(this.legendLabels);

    legend.exit().remove();

    const g = legend
      .enter()
      .append("g")
      .attr("class", this.styles.legend.cssClass);

    const arrowStyle = this.styles.legend.arrow;
    const arrowWidth = this.isMobile ? arrowStyle.width / 3 : arrowStyle.width;

    g.append("text")
      .merge(legend.selectAll("text"))
      .attr("x", (d, i) => (i === 0 ? this.width - arrowWidth : this.labelWidth + arrowWidth))
      .attr("y", this.margin.top)
      .attr("font-size", this.styles.legend.fontSize)
      .attr("text-anchor", (d, i) => (i === 0 ? "end" : "start"))
      .attr("dominant-baseline", "central")
      .attr("dx", (d, i) => (i === 0 ? "-1em" : "1em"))
      .text(d => d);

    g.append("line")
      .merge(legend.selectAll("line"))
      .attr("x1", (d, i) => (i === 0 ? this.width - arrowWidth : this.labelWidth))
      .attr("x2", (d, i) => (i === 0 ? this.width : this.labelWidth + arrowWidth))
      .attr("y1", this.margin.top)
      .attr("y2", this.margin.top)
      .attr("stroke", arrowStyle.stroke)
      .attr("stroke-width", arrowStyle.strokeWidth)
      .attr("opacity", arrowStyle.opacity)
      .attr("marker-end", (d, i) => (i === 0 ? "url(#arrowhead-end)" : null))
      .attr("marker-start", (d, i) => (i === 0 ? null : "url(#arrowhead-start)"));
  }

  handleTitleTexts(groupsMerged) {
    const titles = groupsMerged.selectAll(`text.${this.styles.group.cssClass}`).data(d => [d]);

    titles.exit().remove();

    const titlesEnter = titles
      .enter()
      .append("text")
      .attr("class", this.styles.group.cssClass);

    titles
      .merge(titlesEnter)
      .transition()
      .attr("x", this.labelWidth)
      .attr("y", this.blockHeight / 2)
      .attr("dx", this.isMobile ? "-1em" : "-2em")
      .attr("font-size", this.isMobile ? null : this.styles.group.fontSize)
      .attr("font-weight", this.styles.group.fontWeight)
      .attr("text-anchor", "end")
      .attr("dominant-baseline", "central")
      .text(d => d.key);
  }

  handleTitleRects(groupsMerged) {
    const rects = groupsMerged.selectAll(`rect.${this.styles.group.cssClass}`).data(d => [d]);

    rects.exit().remove();

    const titlesEnter = rects
      .enter()
      .append("rect")
      .attr("class", this.styles.group.cssClass)
      .on("click", this.onTitleRectClick.bind(this))
      .on("mouseover", this.onTitleRectMouseover)
      .on("mouseout", this.onTitleRectMouseout);

    rects
      .merge(titlesEnter)
      .transition()
      .attr("x", this.margin.left)
      .attr("y", this.blockHeight / 4)
      .attr("width", this.labelWidth - 16)
      .attr("height", this.blockHeight / 2)
      .attr("fill", this.styles.group.fill)
      .attr("stroke", this.styles.group.stroke)
      .attr("cursor", "pointer")
      .attr("pointer-events", "visible")
      .attr("opacity", 0)
      .attr("rx", this.styles.group.borderRadius)
      .attr("ry", this.styles.group.borderRadius);
  }

  handleDecorators(groupsMerged) {
    const decorators = groupsMerged.selectAll(`rect.${this.styles.decorator.cssClass}`).data(d => [d]);

    decorators.exit().remove();

    const decoratorsEnter = decorators
      .enter()
      .append("rect")
      .attr("class", this.styles.decorator.cssClass);

    decorators
      .merge(decoratorsEnter)
      .transition()
      .attr("class", this.styles.decorator.cssClass)
      .attr("x", this.labelWidth)
      .attr("y", this.blockHeight / 2 - this.styles.decorator.height / 2)
      .attr("width", this.width)
      .attr("height", this.styles.decorator.height)
      .attr("opacity", this.styles.decorator.opacity)
      .attr("fill", this.styles.decorator.fill);
  }

  handleInnerRects(groupsMerged) {
    const innerRects = groupsMerged.selectAll(`rect.${this.styles.inner.cssClass}`).data(d => d.values);

    innerRects.exit().remove();

    const innerRectsEnter = innerRects
      .enter()
      .append("rect")
      .attr("class", this.styles.inner.cssClass)
      .on("mouseover", this.onInnerRectMouseover.bind(this))
      .on("mouseout", this.onInnerRectMouseout.bind(this));

    innerRects
      .merge(innerRectsEnter)
      .transition()
      .duration(this.transitionTime)
      .attr("class", this.styles.inner.cssClass)
      .attr("x", d => this.x(d[this.xAxisProp]))
      .attr("y", d => this.blockHeight / 2 - this.y1(d[this.yAxisProp]) / 2)
      .attr("width", this.styles.inner.width)
      .attr("height", d => this.y1(d[this.yAxisProp]))
      .attr("opacity", this.styles.inner.opacity)
      .attr("fill", this.styles.inner.fill)
      .attr("cursor", "pointer");
  }

  handleMathRects(groupsMerged) {
    const mathRect = groupsMerged.selectAll("rect.math").data(d => this.getMaths(d.values));

    mathRect.exit().remove();

    const mathRectEnter = mathRect
      .enter()
      .append("rect")
      .attr("class", d => `math ${d.cssClass}`);

    mathRect
      .merge(mathRectEnter)
      .transition()
      .duration(this.transitionTime)
      .attr("class", d => `math ${d.cssClass}`)
      .attr("x", d => this.x(d.value))
      .attr("y", this.blockHeight / 2 - this.blockHeight / 2 / 2)
      .attr("width", 3 * this.styles.inner.width)
      .attr("height", this.blockHeight / 2)
      .attr("fill", d => d.fill);
  }

  handleMathTexts(groupsMerged) {
    const mathText = groupsMerged.selectAll("text.math").data(d => this.getMaths(d.values));

    mathText.exit().remove();

    const mathTextEnter = mathText
      .enter()
      .append("text")
      .attr("class", d => `math ${d.cssClass}`);

    mathText
      .merge(mathTextEnter)
      .transition()
      .duration(this.transitionTime)
      .attr("class", d => `math ${d.cssClass}`)
      .attr("x", d => this.x(d.value))
      .attr("y", 0)
      .attr("text-anchor", (d, i) => (i === 0 ? "end" : "start"))
      .attr("font-size", d => d.fontSize)
      .attr("font-weight", d => d.fontWeight)
      .text(d => d.name);
  }

  onTitleRectClick(clickedElement) {
    if (this.onElementClick) {
      this.onElementClick(parseInt(clickedElement.key));
    }
  }

  onTitleRectMouseover() {
    d3.select(this).attr("opacity", "1");
  }

  onTitleRectMouseout() {
    d3.select(this).attr("opacity", "0");
  }

  onInnerRectMouseover(d) {
    if (this.tooltipContainer) {
      this.tooltipContainer
        .html(this.tooltip(d))
        .style("opacity", 1)
        .style("position", "absolute")
        .style("left", `${this.x(d[this.xAxisProp]) + this.margin.left + this.styles.inner.width / 2}px`)
        .style(
          "top",
          `${this.categories.indexOf(d[this.aggregationProp]) * (this.blockHeight + this.blockGap) +
            this.blockHeight / 2 +
            this.y1(d[this.yAxisProp]) / 2 +
            this.margin.top}px`
        );
    }
  }

  onInnerRectMouseout() {
    if (this.tooltipContainer) {
      this.tooltipContainer.style("opacity", 0);
    }
  }

  getAverage(arr) {
    return arr.reduce((prev, current) => (current += prev)) / arr.length;
  }

  getMedian(arr) {
    arr.sort((a, b) => a - b);
    const lowMiddle = Math.floor((arr.length - 1) / 2);
    const highMiddle = Math.ceil((arr.length - 1) / 2);

    return (arr[lowMiddle] + arr[highMiddle]) / 2;
  }

  getMaths(arr) {
    const avg = this.getAverage(arr.map(d => d[this.xAxisProp]));
    const median = this.getMedian(arr.map(d => d[this.xAxisProp]));

    return [
      {
        name: this.averageLabel,
        value: avg,
        cssClass: this.styles.math.avg.cssClass,
        fill: this.styles.math.avg.fill,
        fontSize: this.styles.math.avg.fontSize,
        fontWeight: this.styles.math.avg.fontWeight
      },
      {
        name: this.medianLabel,
        value: median,
        cssClass: this.styles.math.median.cssClass,
        fill: this.styles.math.median.fill,
        fontSize: this.styles.math.median.fontSize,
        fontWeight: this.styles.math.median.fontWeight
      }
    ].sort((a, b) => a.value > b.value);
  }

  defaultTooltip(d) {
    return `<pre>${JSON.stringify(d, null, 2)}</pre>`;
  }

  defaultStyles(styles = {}) {
    return {
      decorator: {
        cssClass: "decorator",
        opacity: 0.2,
        fill: "#c0c0c0",
        height: 20,
        ...styles.decorator
      },
      inner: {
        cssClass: "inner",
        opacity: 0.3,
        fill: "#f8b207",
        width: 2,
        ...styles.inner
      },
      math: {
        avg: {
          cssClass: "avg",
          fill: "#01909e",
          fontSize: 14,
          fontWeight: "100",
          ...(styles.math || {}).avg
        },
        median: {
          cssClass: "median",
          fill: "#f69c95",
          fontSize: 14,
          fontWeight: "100",
          ...(styles.math || {}).median
        }
      },
      legend: {
        cssClass: "legend",
        fontSize: 14,
        arrow: {
          width: 100,
          stroke: "#979797",
          strokeWidth: 2,
          opacity: 0.2,
          ...(styles.legend || {}).arrow
        },
        ...styles.legend
      },
      group: {
        cssClass: "agg-title",
        fontSize: 18,
        fontWeight: "bold",
        borderRadius: 3,
        stroke: "#d8d8d8",
        fill: "none",
        ...styles.group
      }
    };
  }
}
