<template>
  <Multiselect
    v-model="value"
    :options="options"
    :multiple="multiple"
    :open-direction="openDirection"
    :placeholder="placeholder"
    :max-height="maxHeight"
    :track-by="trackBy"
    :label="label"
    select-label
    selected-label
    deselect-label
    @input="handleSelectPivotTable"
  ></Multiselect>
</template>

<script>
import Multiselect from "vue-multiselect";

export default {
  name: "PivotTableSelector",
  props: {
    multiple: Boolean,
    options: Array,
    default: [String, Number, Array],
    openDirection: String,
    placeholder: String,
    groupLabel: String,
    groupValues: String,
    className: String,
    maxHeight: Number,
    label: String,
    trackBy: String
  },
  components: { Multiselect },
  data() {
    return {
      value: null
    };
  },
  watch: {
    default(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.value = newValue;
      }
    }
  },
  mounted() {
    if (this.default) {
      this.value = this.default;
    }
  },
  methods: {
    handleSelectPivotTable(value) {
      this.$emit("handleSelectPivotTable", value);
    }
  }
};
</script>
