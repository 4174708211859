import axios from "axios";

const createAxiosInstance = config => {
  const __instance = config.hasOwnProperty("setup") ? axios.create(config.setup) : axios.create();
  __instance.interceptors.response.use(async response => {
    let data = response.data;

    // When the content type is CSV, override response.data with the CSV parsed
    if (response.headers["content-type"] === "text/csv") {
      const lib = await import("papaparse");
      const Papa = lib.default;

      data = Papa.parse(response.data, { header: true, delimiter: "," }).data;
      response.data = data;
    }

    const isEmptyData = Object.entries(data).length === 0 && (data.constructor === Object || data.constructor === Array);
    if (isEmptyData) {
      // eslint-disable-next-line no-console
      console.warn(`${response.config.url} returns no data.`);
    }

    return response;
  });

  return __instance;
};

const handleAxiosError = err => {
  if (axios.isCancel(err)) {
    // eslint-disable-next-line no-console
    console.warn("Cancelling previous requests");
  }
};

export class Factory {
  constructor(opts) {
    // initial setup
    this.config = opts;

    // current axios
    this.instance = {};

    // current axios.source()
    this.source;

    const self = this;

    // default factory
    this.commons = {
      get(params = {}) {
        const url = this.build(params);

        let req;

        if (self.source) {
          self.source.cancel();
        }

        self.source = axios.CancelToken.source();

        if (Array.isArray(url)) {
          req = [];
          url.forEach(u =>
            req.push(
              self.instance.get(u, {
                cancelToken: self.source.token
              })
            )
          );
        } else if (typeof url === "string" || url instanceof String) {
          req = self.instance.get(url, {
            cancelToken: self.source.token
          });
        }

        return Array.isArray(req)
          ? new Promise(resolve =>
              Promise.all(req)
                .then(res => resolve(this.parse(res.map(r => r.data))))
                .catch(handleAxiosError)
            )
          : new Promise(resolve => req.then(res => resolve(this.parse(res.data))).catch(handleAxiosError));
      },
      build() {},
      parse() {}
    };
  }

  load(id) {
    if (!(this.instance instanceof Function)) {
      // Not initializated
      this.instance = createAxiosInstance(this.config);
    }

    const { endpoints = {} } = this.config;
    const configuration = Array.isArray(endpoints[id]) ? endpoints[id] : { ...this.commons, ...endpoints[id] };

    return configuration;
  }
}
