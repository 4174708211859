<template>
  <div>
    <div v-if="loading && !empty">Cargando...</div>
    <div
      v-else-if="loading && empty"
    >Dataset sin resultados para el filtro seleccionado. Se procede a buscar para {{ info }} ...</div>
    <div v-else-if="!loading && empty">Dataset sin resultados</div>
  </div>
</template>
<script>
export default {
  props: {
    loading: Boolean,
    empty: Boolean,
    info: [Number, String]
  }
};
</script>
