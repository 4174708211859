<template>
  <div>
    <BreadCrumb
      :bread-crumb-last="breadCrumbValue"
      :show-map="showMap"
      :language="language"
      :key="language"
      @handleMap="backToMap"
    />
    <template v-if="showMap">
      <WorldMap
        @showMap="handleMap"
        :properties="properties"
        :factory="factory"
        :language="language"
        :id="id"
        :show-time-line="showTimeLine"
      />
    </template>
    <template v-else>
      <component
        :is="currentChart"
        :id="id"
        class="border-container-chart"
        :properties="properties"
        :factory="factory"
        :pivot-table-value="properties.pivotTableValue"
        :pivot-table-show="properties.pivotTableSelector"
        :pivot-default-value="mapSelectedValue"
        :container="hasContainer"
        :language="language"
        @updateBreadCrumb="handleBreadCrumb"
      ></component>
    </template>
  </div>
</template>
<script>
import { VisualizationMixin } from "../../assets/mixins";
import WorldMap from "./Map";
import LineChart from "./Line";
import BreadCrumb from "../selectors/BreadCrumb";
export default {
  name: "MapContainer",
  components: {
    WorldMap,
    LineChart,
    BreadCrumb
  },
  mixins: [VisualizationMixin],
  data() {
    return {
      data: [],
      chart: null,
      cacheMap: null,
      showMap: true,
      sourceData: "Agrega un título a la fuente de datos",
      sourceLink: "Agrega el enlace a la fuente de datos",
      mapSelectedValue: null,
      breadCrumbValue: null,
      hasContainer: null,
      showTimeLine: false
    };
  },
  props: {
    properties: {
      type: Object,
      default: () => {}
    },
    language: {
      type: String,
      default: ''
    }
  },
  created() {
    this.hasContainer = this.properties.hasContainer
    this.showTimeLine = this.properties.timeLine
    this.currentChart = () => import(`./Line`);
  },
  methods: {
    handleMap(value, name) {
      this.properties.mapProperty = name
      this.properties.mapPropertyInverted = this.factory.config.endpoints.series.find(d => d.id === name).inverted || false
      this.properties.defaults.x = [name]
      this.mapSelectedValue = value
      this.breadCrumbValue = value
      this.showMap = false
    },
    backToMap() {
      this.showMap = true
    },
    handleBreadCrumb(breadCrumbValue){
      this.breadCrumbValue = breadCrumbValue
    }
  }
};
</script>
<style>
.chart-wrapper {
  aspect-ratio: 16 / 9;
}

.border-container-chart #line-chart {
  padding-top: .5rem;
  border: 1px solid rgba(151, 151, 151, .2);
}
</style>
