import { select, selectAll } from "d3-selection";
import { scaleQuantile, scaleLinear } from "d3-scale";
import { extent, range } from "d3-array";
import { axisRight } from "d3-axis";
import { format } from 'd3-format';
import "d3-transition";

const d3 = {
  select,
  selectAll,
  scaleQuantile,
  scaleLinear,
  axisRight,
  extent,
  range,
  format
};

const getEvent = () => require("d3-selection").event;

export default class {
  constructor(props) {
    if (!props.ctx) {
      return new Error("No markup provided.");
    }
    this.container = d3.select(props.ctx);
    this.data = props.data || [];
    this.currentYear = props.firstYear
    this.props = props;
    this.showCountries = this.props.countries || "todos"

    this.chartContainer = this.container
      .append("div")
      .attr("class", "chart-container");

    this.tooltip = this.container
      .append("div")
      .attr("class", "tooltip-heatmap")

    if (this.data.length) {
      this.draw();
    }

    window.addEventListener("resize", this.draw.bind(this));
  }

  draw() {
    this.setElements()
    this.drawHeat()
  }

  setElements() {
    // Dimensions
    this.width = +this.container.node().getBoundingClientRect().width;

    this.chartContainer
      .style("width", `${this.width}px`)
      .style("display", "flex")
  }

  drawHeat(){
    this.getNames = Array.from([...new Set(this.data.groups.map(({ name }) => name).filter(d => d))]);
    this.getValues = Array.from([...new Set(this.data.groups.map(({ value }) => value).filter(d => d))]).sort((a,b) => b - a);
    this.getDates = Array.from([...new Set(this.data.groups.map(({ time }) => time).filter(d => d))]);
    this.getSeries = Array.from([...new Set(this.data.groups.map(({ serie }) => serie).filter(d => d))]);
    this.getDevelopedKey = Array.from([...new Set(this.data.groups.map(({ developed_country }) => developed_country))]);
    this.filterDataByCountry = [...new Set(this.data.groups.map(d => ({
      country: d.country,
      developed_country: d.developed_country
    })))].filter((v,i,a) => a.findIndex(v2 => ['country', 'developed_country'].every(k => v2[k] === v[k])) === i);

    this.developedKey = document.documentElement.lang === 'es' ? "desarrollado" : "developed";
    this.developingKey = document.documentElement.lang === 'es' ? "emergente" : "developing";

    if(this.showCountries === "desarrollados"){
      this.filterDataByCountry = this.filterDataByCountry.filter(({ developed_country }) => developed_country === this.developedKey);
    }

    if(this.showCountries === "emergentes"){
      this.filterDataByCountry = this.filterDataByCountry.filter(({ developed_country }) => developed_country === this.developingKey);
    }

    this.extent = d3.extent(this.getValues, d => d);

    this.chartContainer
      .selectAll(".countries-data-column")
      .remove()

    this.chartContainer
      .selectAll(".countries-column")
      .remove()

    this.scaleColor = d3.scaleQuantile()
      .domain(this.extent)
      .range(['#c51b7d','#de77ae','#f1b6da','#fde0ef','#e6e4e4','#e6f5d0','#b8e186','#7fbc41','#4d9221'])

    this.chartContainer
      .append("div")
      .attr("class", "countries-column")
      .append("div")
      .attr("class","countries-column-legends")
      .selectAll(".countries-column-legends__element")
      .data(this.getDevelopedKey)
      .enter()
      .append("div")
      .attr("class", "countries-column-legends__element")
      .html(d => {
        if(this.showCountries !== "todos"){
          return
        }
        return `<span class="countries-column-legends__element-legend ${d === this.developedKey ? "developed-country" : "developing-country"}"></span>
        <span class="countries-column-legends__element-text">${document.documentElement.lang === 'es' ? `País ${d}` : `${d} country`}</span>
        `
      })

    this.chartContainer
      .select(".countries-column")
      .selectAll(".countries-column__element")
      .data(this.filterDataByCountry)
      .join((enter) =>
        enter
          .append("div")
          .attr("class", d => {
            const classDevelopedCountry =
              d.developed_country === this.developedKey && this.showCountries === "todos"
                ? "developed-country"
                : d.developed_country === this.developingKey && this.showCountries === "todos"
                ? "developing-country"
                : "";
            return `countries-column__element ${classDevelopedCountry}`
          })
          .html((d) => `<span class="countries-column__element-text">${d.country}</span>`)
      )

    this.getSeries.forEach((serie, index) => {
      this.chartContainer
        .append("div")
        .attr("class", "countries-data-column")
        .attr("id", `${serie}`)
        .append("div")
        .attr("class", "countries-data-column__header")
        .append("span")
        .attr("class", "countries-data-column__header-title")
        .attr("title", this.getNames[index])
        .text(this.getNames[index])

      this.chartContainer
        .select(`#${serie} .countries-data-column__header`)
        .selectAll(`#${serie} .countries-data-column__header-text`)
        .data(this.getDates)
        .enter()
        .append("span")
          .attr("class", "countries-data-column__header-text")
          .attr("title", d => d)
          .text(d => d)

      if(this.showCountries === "todos"){
        this.filterDataByKeyType = this.filterDataByKey(this.data.raw, `${serie}`);
      }

      if(this.showCountries === "desarrollados"){
        const filterDevelopedCountries = this.data.raw.filter(({ developed_country }) => developed_country === this.developedKey)
        this.filterDataByKeyType = this.filterDataByKey(filterDevelopedCountries, `${serie}`);
      }

      if(this.showCountries === "emergentes"){
        const filterDevelopingCountries = this.data.raw.filter(({ developed_country }) => developed_country === this.developingKey)
        this.filterDataByKeyType = this.filterDataByKey(filterDevelopingCountries, `${serie}`);
      }

      const groupedCountries = this.groupObjectsByKey(this.filterDataByKeyType, "country");

      this.chartContainer.selectAll(`#${serie}`)
        .selectAll(`#${serie} .countries-column__element`)
        .data(groupedCountries)
        .join((enter) =>
          enter
            .append("div")
            .attr("class", "countries-column__element")
            .selectAll(".countries-column__element-text")
            .data(d => [[d[this.getDates[0]]], [d[this.getDates[1]]]])
            .enter()
            .append("div")
            .attr("class", d => `countries-column__element-text ${d}`)
            .style("background-color", d => this.scaleColor(+d[0]))
            .on("mouseover", () => {
              this.tooltip
                .transition()
                .duration(200)
                .style("opacity", 1)
                .style("display", "block")
            })
            .on("mousemove", d => this.showTooltip(d, serie))
            .on("mouseleave", () => {
              this.tooltip
                .style("pointer-events", "none")
                .transition()
                .duration(200)
                .style("opacity", 0)
                .style("display", "none")
            })
        )
    })

    this.buildLegend()
  }

  buildLegend(){
    this.height = +this.container.node().getBoundingClientRect().height / 3;

    const barWidth = 10;

    this.chartContainer
      .select('.legend-scale')
      .remove()

    this.svg = this.chartContainer
      .append("svg")
      .attr("class", "legend-scale")

    this.svg
      .attr("width", "100px")
      .attr("height", this.height)

    const xScale = d3
      .scaleLinear()
      .range([0, this.height - 24])
      .domain(this.extent.reverse());

    const maxValue = Math.max(...this.getValues);
    const minValue = Math.min(...this.getValues);
    const ticksAmount = 5;
    const tickStep = (maxValue - minValue) / (ticksAmount);
    const step = Math.ceil(tickStep / 4) * 2;

    const xAxis = d3
      .axisRight(xScale)
      .tickSize(barWidth * 2)
      .ticks(ticksAmount)
      .tickValues(d3.range(minValue, maxValue + step, step))
      .tickFormat(d3.format('d'))

    const g = this.svg
      .append("g")
      .attr("transform", "translate(0, 12)");

    const defs = this.svg.append("defs");

    const linearGradient = defs
      .append("linearGradient")
      .attr("id", "scaleGradient")
      .attr("x1", "0")
      .attr("x2", "0")
      .attr("y1", "0")
      .attr("y2", "100%")

    linearGradient
      .selectAll("stop")
      .data(this.getValues)
      .enter()
      .append("stop")
      .attr("offset", d => ((d - this.extent[0]) / (this.extent[1] - this.extent[0]) * 100 + "%"))
      .attr("stop-color", d => this.scaleColor(d))

    g.append("rect")
      .attr("width", barWidth)
      .attr("height", this.height - 24)
      .attr("fill", "url(#scaleGradient)")
      .attr("stroke", "#999")
      .attr("stroke-width", "1px");

    g.append("g")
      .call(xAxis)
      .select(".domain")
      .remove();
  }

  groupObjectsByKey(objects, key) {
    const groupedObjects = {};

    for (const obj of objects) {
      const keyValue = obj[key];

      if (!groupedObjects[keyValue]) {
        groupedObjects[keyValue] = { [key]: keyValue };
      }

      for (const prop in obj) {
        if (prop !== key) {
          groupedObjects[keyValue][prop] = obj[prop];
        }
      }
    }

    return Object.values(groupedObjects);
  }

  filterDataByKey(data, value){
    return data.filter(d => d[value]).map(d => {
      const ano_rango = d.ano_rango
      return {
        [ano_rango]: d[value],
        country: d.country
      }
    });
  }

  showTooltip(item, serie) {
    const widthTooltip = this.tooltip.node().offsetWidth;
    const heightTooltip = this.tooltip.node().offsetHeight;
    let positionTooltip
    if(getEvent().layerX < widthTooltip) {
      positionTooltip = (widthTooltip / 2)
    } else if ((getEvent().layerX + widthTooltip) > this.width) {
      positionTooltip = this.width - (widthTooltip / 2)
    } else {
      positionTooltip = getEvent().layerX
    }

    this.tooltip
      .style("left", `${positionTooltip - (widthTooltip / 2) + 50}px`)
      .style("top", `${getEvent().layerY - (heightTooltip + 10)}px`)

    this.tooltip
      .html(`
        <span class="tooltip-title">${this.data.groups.filter(d => d.serie === serie).map(d => d.name)[0]}: </span>
        <span class="tooltip-text">${item}</span>
        `
      )
  }
}
