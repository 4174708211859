<template>
  <div :id="this.id">
    <template v-if="loadConfig">
      <div class="row mb-1">
        <ChartTypes
          :id="this.id"
          :active-charts="activeCharts"
        />
        <div class="container-title-description">
          <h1 class="title-chart">
            {{ titleChart }}
          </h1>
          <ShareButtons
            :id="this.id"
            :config="config"
            :language="selectedLanguage"
            @change-language-app="handleLocale"
          />
          <p class="description-chart" v-html="descriptionChart"/>
        </div>
      </div>
      <Visualization
        :id="this.id"
        :config="config"
        :factory="factory"
        :language="selectedLanguage"
      />
      <SourceData
        :config="config"
        :language="selectedLanguage"
      />
    </template>
  </div>
</template>

<script>
import ChartTypes from "./components/chartTypes/ChartTypes";
import ShareButtons from "./components/ShareButtons";
import Visualization from "./components/Visualization";
import SourceData from "./components/SourceData";
import { Factory } from "./assets/middleware";

export default {
  name: "Visualizer",
  props: {
    id: {
      type: String,
      default: "visualizer"
    },
    config: Object
  },
  components: {
    ChartTypes,
    ShareButtons,
    Visualization,
    SourceData
  },
  data() {
    return {
      factory: null,
      loadConfig: false,
      activeCharts: [],
      titleChart: 'Edita el título de la gráfica',
      descriptionChart: '',
      selectedLanguage: ''
    };
  },
  watch: {
    language() {
      this.translateTitle()
      this.setConfig();
    }
  },
  created() {
    if (this.config) {
      this.setConfig();
      this.selectedLanguage = this.config.charts[0].language
      document.documentElement.lang = this.config.charts[0].language
    }
    this.translateTitle()
  },
  updated() {
    if (this.config && !this.loadConfig) {
      this.setConfig();
      this.selectedLanguage = this.config.charts[0].language
      document.documentElement.lang = this.config.charts[0].language
    }
  },
  methods: {
    translateTitle() {
      if (this.config && this.config.charts[0].title.length === 0) {
        this.titleChart = this.selectedLanguage === 'es' ? 'Edita el título de la gráfica' : 'Edit the title of the chart'
      }
    },
    handleLocale(language) {
      this.selectedLanguage = language
      this.config.charts[0].language = language
      document.documentElement.lang = language
      this.language = language
      this.translateTitle()
    },
    setConfig() {
      const factory = new Factory(this.config);

      this.activeCharts = factory.config.charts;
      this.factory = factory;

      this.loadConfig = true;

      //Check if string values are empty or undefined
      if(this.config.charts[0].title === "" || this.config.charts[0].title === undefined) {
        this.titleChart = this.titleChart
      } else {
        this.titleChart = this.config.charts[0].title
      }

      if(this.config.charts[0].description === "" || this.config.charts[0].description === undefined) {
        this.descriptionChart = this.descriptionChart
      } else {
        this.descriptionChart = this.config.charts[0].description
      }

      this.$root.$on('emitTitleValues', this.updateTitle)
      this.$root.$on('emitDescriptionValues', this.updateDescription)
    },
    updateTitle(value) {
      this.titleChart = value
      if(value.length === 0) this.translateTitle()
    },
    updateDescription(value) {
      this.descriptionChart = value
    }
  }
};
</script>
<style>
@import url('https://fonts.googleapis.com/css?family=Libre+Franklin:400,600,700&display=swap');

html {
  font-family: 'Libre Franklin', sans-serif;
}

.row {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
}

.row--theme {
  background-color: rgba(1, 144, 158, 0.1);
  border-radius: 4px;
}

.mb-1 {
  margin-bottom: 1rem;
}

.container-title-description {
  display: inline-block;
  width: 100%;
}

.title-chart {
  font-size: 18px;
  color: #333;
  font-family: Arial, 'sans-serif';
  display: inline-block;
  width: 70%;
}

.description-chart {
  max-width: 96ch;
  font-size: 14px;
  font-family: Arial, 'sans-serif';
  color: rgba(51, 51, 51, .75);
  margin-top: 0;
}

@media screen and (min-width: 768px) {
  .row {
    flex-direction: row;
    align-items: center;
  }
}
</style>
