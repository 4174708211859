import { group, rollup } from 'd3-array'
import { select, selectAll, mouse } from 'd3-selection';
import { min, max, extent, bisector } from 'd3-array';
import { line, curveBasis, area, stack } from 'd3-shape';
import { scaleTime, scaleLinear, scaleLog } from 'd3-scale';
import { axisBottom, axisLeft } from 'd3-axis';
import { format, formatDefaultLocale } from 'd3-format';
import { schemeCategory10 } from 'd3-scale-chromatic';
import { timeFormat } from 'd3-time-format';
import { nest } from 'd3-collection';
import { forceSimulation, forceCollide, forceY } from 'd3-force';
import { easeLinear } from 'd3-ease';
import 'd3-transition';

const d3 = {
  select,
  selectAll,
  min,
  max,
  extent,
  scaleLog,
  line,
  nest,
  scaleTime,
  scaleLinear,
  axisBottom,
  axisLeft,
  format,
  timeFormat,
  formatDefaultLocale,
  group,
  easeLinear,
  rollup,
  schemeCategory10,
  curveBasis,
  forceSimulation,
  forceCollide,
  forceY,
  bisector,
  mouse,
  area,
  stack
};

const getEvent = () => require("d3-selection").event;

export default class {
  constructor(props) {
    if (!props.ctx) {
      return new Error("No markup provided.");
    }

    this.container = d3.select(props.ctx);
    this.data = props.data || [];
    this.props = props;

    // Options
    this.minHeight = props.minHeight;
    this.maxWidth = props.maxWidth;
    this.xAxisProp = props.xAxisProp;
    this.yAxisProp = props.yAxisProp;
    this.aggregationProp = props.aggregationProp;

    this.tooltip = props.tooltip || this.defaultTooltip;
    this.palette = props.palette || d3.schemeCategory10;
    this.defaultColor = props.defaultColor || this.palette[Math.floor(Math.random() * this.palette.length)];
    this.ticksXAxis = props.ticksXAxis !== undefined ? props.ticksXAxis : 20;
    this.ticksYAxis = props.ticksYAxis !== undefined ? props.ticksYAxis : 5;
    this.tickFormat = props.tickFormat;
    this.tickFormatX = props.tickFormatX || this.tickFormat;
    this.tickFormatY = props.tickFormatY || this.tickFormat;
    this.defaultTickFormatX = (d, i) => (!(i % (this.isMobile ? 6 : 2)) ? d3.timeFormat("%Y")(d) : null);
    this.defaultTickFormatY = d3.format('.0f');
    this.transitionTime = props.transitionTime !== undefined ? props.transitionTime : 1000;
    this.styles = this.defaultStyles(props.styles);
    this.tooltipContainer = props.tooltipContainer ? d3.select(props.tooltipContainer) : false;
    this.circleDataFilter = props.circleDataFilter;
    this.xScaleFunction = props.xScaleFunction || (d3 => d3.scaleLinear());
    this.yScaleFunction = props.yScaleFunction || (d3 => d3.scaleLinear());
    this.yScaleFunctionString = this.yScaleFunction.toString()
    this.hasContainer = props.hasContainer
    this.showBspline = props.bspline


    // Create main elements
    this.svg = this.container.append("svg");
    this.g = this.svg.append("g");
    this.g.append("g").attr("class", "x axis");
    this.g.append("g").attr("class", "y axis");

    if (this.data.length) {
      this.draw();
    }

    window.addEventListener("resize", this.draw.bind(this));
  }

  draw() {
    this.props.type = this.props.typeChart === 'Stacked' ? 'Stacked' : 'Line'
    const typeLinesCharts = ['MapContainer', 'Line'];
    this.data = this.data.sort((a,b) => new Date(a.time) - new Date(b.time));
    if (this.data.length && typeLinesCharts.includes(this.props.type)) {
      this.responsiveness();
      this.setElements();
      this.handleLines();

    } else if (this.data.length && this.props.type === "Stacked") {
      this.responsiveness();
      this.setElements();
      this.handleStack();
    }

  }

  responsiveness() {
    this.isMobile = document.documentElement.clientWidth < 768;
    const widthMobile = window.innerWidth > 0 ? window.innerWidth : screen.width;

    this.margin = {
      top: this.props.marginTop !== undefined ? this.props.marginTop : 55,
      right: widthMobile <= 768 ? 105 : 200,
      bottom: this.props.marginBottom !== undefined ? this.props.marginBottom : 50,
      left: this.props.marginLeft !== undefined ? this.props.marginLeft : 50
    };

    this.margin.left = this.isMobile ? 30 : this.margin.left

    this.aspectRatio = this.props.aspectRatio;
  }

  setElements() {

    this.categories = [...new Set(this.data.map(d => d[this.aggregationProp]))].sort();

    // Dimensions
    this.width = Math.min(this.maxWidth, +this.container.node().getBoundingClientRect().width - this.margin.left - this.margin.right);
    this.height = Math.max(this.minHeight, this.width / this.aspectRatio - this.margin.top - this.margin.bottom);


    // Scales & Ranges
    const flatData = this.data.flat();

    this.xScale = d3
      .scaleTime()
      .range([0, this.width])
      .domain(d3.extent(flatData, d => d[this.xAxisProp]));

    if (this.data[0].units === "%" && this.yScaleFunctionString.includes('d3.scaleLinear')) {
      const flatData = this.data.flat();
      const arrayScales = d3.extent(flatData, d => d[this.yAxisProp])
      const domainScalesLinear = arrayScales[1] > 6 ? [0, 100] : arrayScales
      this.yScale = this.yScaleFunction(d3)
        .range([this.height, 0])
        .domain(domainScalesLinear);
    } else {
      this.yScale = this.yScaleFunction(d3)
        .range([this.height, 0])
        .domain(d3.extent(flatData, d => d[this.yAxisProp]));
    }

    // Positions
    this.svg
      .attr("width", this.width + this.margin.left + this.margin.right)
      .attr("height", this.height + this.margin.top + this.margin.bottom);
    this.g.attr("transform", `translate(${this.margin.left},${this.margin.top})`);

    // Create axes
    this.g
      .select(".x.axis")
      .attr("transform", `translate(0,${this.height})`)
      .call(this.customXAxis.bind(this));

    this.g
      .select(".y.axis")
      .transition()
      .duration(300)
      .call(this.customYAxis.bind(this));
  }

  changeScaleLog() {

    this.yScaleFunction = (d3 => d3.scaleLog());
    this.yScaleFunctionString = this.yScaleFunction.toString()

    this.draw()

  }

  changeScaleLogNatural() {

    this.yScaleFunction = (d3 => d3.scaleLog().base(Math.E));
    this.yScaleFunctionString = this.yScaleFunction.toString()

    this.draw()

  }

  changeScaleLinear() {

    this.yScaleFunction = (d3 => d3.scaleLinear());
    this.yScaleFunctionString = this.yScaleFunction.toString()
    this.draw()

  }

  handleLines() {

    this.props.type = "Line"

    const flatData = this.data.flat();

    this.yScale = this.yScaleFunctionString.includes('Math.E')
    ? this.yScaleFunction(d3)
        .range([this.height, 0])
        .domain(d3.extent(flatData, d => d[this.yAxisProp])).nice()
    : this.yScaleFunction(d3)
        .range([this.height, 0])
        .domain(d3.extent(flatData, d => d[this.yAxisProp]));

    this.g.select(".y.axis")
      .transition()
      .duration(300)
      .call(this.customYAxis.bind(this));

    const dataByGroups = d3
      .nest()
      .key(d => d[this.aggregationProp])
      .entries(this.data);

    const groups = this.g.selectAll(`g.line`).data(dataByGroups);

    groups.exit().remove();

    const groupsEnter = groups
      .enter()
      .append("g")
      .attr("class", (_, i) => `line --line-${i}`);

    const groupsMerged = groups.merge(groupsEnter);

    this.handlePaths(groupsMerged);
    this.handleHorizontalPath();

  }

  handleStack() {
    this.props.type = "Stacked"
    const dataByGroups = d3
      .nest()
      .key(d => d[this.aggregationProp])
      .entries(this.data);

    const groups = this.g.selectAll(`g.line`).data(dataByGroups);

    groups.exit().remove();

    const groupsEnter = groups
      .enter()
      .append("g")
      .attr("class", (_, i) => `line --line-${i}`);

    const groupsMerged = groups.merge(groupsEnter);
    this.handleStacked(groupsMerged)
    this.handleHorizontalPath()
  }

  handleHorizontalPath() {
    //convert time to get quarter
    function getQuarter(d) {
      d = d || new Date();
      var m = Math.floor(d.getMonth() / 3) + 1;
      m -= m > 4 ? 4 : 0;
      return [m];
    }

    //reasign height
    const width = this.width
    const height = this.height
    const xScale = this.xScale

    let anotherParent = !this.hasContainer ? this.container.node().parentNode.parentNode.parentNode.id : this.container.node().parentNode.parentNode.parentNode.parentNode.id
    anotherParent = anotherParent === null ? '' : anotherParent
    const dataByGroups = d3
      .nest()
      .key(d => d[this.aggregationProp])
      .sortKeys(d3.ascending)
      .entries(this.data);

    this.svg
      .selectAll('.rect-mouse-effects')
      .remove()
      .exit()

    const parentSelectId = d3.select('#' + anotherParent)

    const tooltip =
      parentSelectId
      .select('.tooltip')
      .attr("class", "tooltip tooltip-container tooltip" + anotherParent)

    this.tooltipPath = this.svg.append('g')
      .attr('class', 'rect-mouse-effects')
      .attr('transform', `translate(${this.margin.left},${this.margin.top})`);

    this.tooltipPath.append('path')
      .attr('class', 'vertical-line' + anotherParent)
      .style('stroke', '#CACACA')
      .style('stroke-width', 2)
      .style('opacity', 0);

    this.circleLine = this.tooltipPath.selectAll('.circle-line')
      .data(dataByGroups)
      .enter()
      .append('g')
      .attr('class', 'circle-line'  + anotherParent)

    this.tooltipPath.append('rect')
      .attr('class', 'overlay-tooltip')
      .attr('width', this.width - 5)
      .attr('height', height)
      .attr('fill', 'none')
      .attr('pointer-events', 'all')

    this.tooltipPath
      .on('mouseout', function() {
        d3.select('.vertical-line' + anotherParent)
          .style('opacity', 0)
        d3.selectAll('.circle' + anotherParent)
          .style('opacity', 0)
        d3.select('.tooltip' + anotherParent)
          .style('opacity', 0)
          .style('display', "none")
      })
      .on('mouseover', function() {
        d3.select('.vertical-line' + anotherParent)
          .style('opacity', 1)
        d3.selectAll('.circle' + anotherParent)
          .style('opacity', 1)
        d3.select('.tooltip' + anotherParent)
          .style('display', "block")
          .style('opacity', 1)
      })
      .on('mousemove', function(){
        const mouse = d3.mouse(this)

        d3.selectAll(".circle-line" + anotherParent)
          .attr("transform", (d) => {
            const xDate = xScale.invert(mouse[0])
            const bisect = d3.bisector(d => d.time).left
            let idx = bisect(d.values, xDate);
            if(d.values[idx] === undefined) {
              idx = d.values.length - 1;
            }
            d3.select(".vertical-line" + anotherParent)
              .attr("d", () => {
                let data = `M${xScale(d.values[idx].time)},${height}`;
                data += ` ${xScale(d.values[idx].time)},${0}`;
                return data;
              });
            const positionX = xScale(d.values[idx].time)
            const tooltipWidth = d3.select('.tooltip' + anotherParent).node().getBoundingClientRect().width;
            const positionWidthTooltip = positionX + (tooltipWidth / 2);
            const positionWidthSplit = (tooltipWidth / 2);
            const positionleft = `${getEvent().pageX}px`;

            d3.select('.tooltip' + anotherParent)
              .style('left', function() {
                if (positionWidthTooltip < tooltipWidth) {
                  return positionWidthSplit
                } else if (positionWidthTooltip > width) {
                  return tooltipWidth
                } else {
                  return positionleft
                }
              })
          });
        updateTooltip(mouse, dataByGroups)
      })

    const self = this
    function updateTooltip(mouse, dataset) {
      const sortingObj = []
      dataset.map(d => {
        const xDate = xScale.invert(mouse[0])
        const bisect = d3.bisector(d => d.time).left
        const idx = bisect(d.values, xDate);
        sortingObj.push({
          name: d.values[idx].name,
          group: d.values[idx].group,
          key: d.values[idx].serie,
          year: d.values[idx].time.getFullYear(),
          quarter: getQuarter(d.values[idx].time),
          tooltip: d.values[idx].idTooltip
        })
      })

      const locale = d3.formatDefaultLocale({
        decimal: ',',
        thousands: '.',
        grouping: [3]
      });

      tooltip.html(() => {
        return sortingObj[0].tooltip === "rem"
          ? `<h4 class="tooltip-line-date">${sortingObj[0].year} Q${sortingObj[0].quarter}</h4>`
          : `<h4 class="tooltip-line-date">${sortingObj[0].year}</h4>`
        })
        .html(() => {
        return self.aggregationProp === self.props.pivotTableValue
            ? `<h4 class="tooltip-line-date">${sortingObj[0].year}</h4>
              <span class="tooltip-element-top">${sortingObj[0].name}</span>`
            : `<h4 class="tooltip-line-date">${sortingObj[0].year}</h4>`
        })
        .style('top', "150px")
        .selectAll()
        .data(dataset)
        .enter()
        .append('div')
        .attr('class', 'tooltip-line-container')
        .html(d => {
          const { key } = d
          const formatValues = key === 'poblacion' ? locale.format('.1f') : locale.format(',.0f')
          const formatValuesDecimal = locale.format(',.2f')
          const xDate = xScale.invert(mouse[0])
          const bisect = d3.bisector(d => d.time).left
          const idx = bisect(d.values, xDate)
          const units = d.values[idx].units ? d.values[idx].units : ''
          const valueTooltip = d.values[idx].value > 1 ? formatValues(d.values[idx].value) : formatValuesDecimal(d.values[idx].value)
          const valueString = isNaN(d.values[idx].value) ? '-' : `${valueTooltip} ${units}`
          const valueName = self.aggregationProp === 'serie' ? d.values[idx].name : key
          return `
            <div class="tooltip-element">
              <span class="tooltip-element-left">${valueName}</span>
              <span class="tooltip-element-right">${valueString}</span>
            </div>
            `
        })

    }

    this.handleLegends();

  }
  hexToRGB(hex, alpha) {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    return alpha ? `rgba(${r}, ${g}, ${b}, ${alpha})` : `rgb(${r}, ${g}, ${b})`
  }

  handleLegends() {

    setTimeout(() => {
      const dataByGroups = d3
        .nest()
        .key(d => d[this.aggregationProp])
        .entries(this.data);
      const self = this

      const labels = dataByGroups.map(d => {
        return {
          fx: 0,
          targetY: this.yScale(d.values.filter(el => !isNaN(el.value) && el.value !== null).slice(-1)[0].value)
        };
      });

      const forceClamp = (min, max) => {
        let nodes;
        const force = () => {
          nodes.forEach(n => {
            if (n.y > max) n.y = max;
            if (n.y < min) n.y = min;
          });
        };
        force.initialize = (_) => nodes = _;
        return force;
      }

      const force = d3.forceSimulation()
        .nodes(labels)
        .force('collide', d3.forceCollide(18 / 2))
        .force('y', d3.forceY(d => d.targetY).strength(1))
        .force('clamp', forceClamp(0, this.height))
        .stop();

      for (let i = 0; i < 300; i++) force.tick();

      dataByGroups.forEach((values, i) => values.y = labels[i].y);

      this.container
        .selectAll('.legend-item-right')
        .data(dataByGroups)
        .join('span.legend-item-right')
          .attr('class', 'legend-item-right')
          .attr('id', d => d.key)
          .style('position', 'absolute')
          .style('left', `${this.width + this.margin.left + 16}px`)
          .style('top', d => `${d.y + this.margin.top - 5}px `)
          .style('color', d => this.palette[this.categories.indexOf(d.key) % this.palette.length])
          .html(d => {
            return self.aggregationProp === self.props.pivotTableValue ? `<span class="legend-item-right-text">${d.values[0][self.props.pivotTableValue]}</span>` : `<span class="legend-item-right-text">${d.values[0].name}</span>`
          })

      this.container
        .selectAll('.close')
        .data(dataByGroups)
        .style('position', 'absolute')
        .style('left', function() {
          return self.isMobile ? `${self.width + self.margin.left + 90}px` : `${self.width + self.margin.left + 160}px`
        })
        .style('top', d => `${d.y + this.margin.top - 5}px `)
        .style("background-color", d => this.hexToRGB(this.palette[this.categories.indexOf(d.key) % this.palette.length], 0.5))
    }, 500);
  }

  handlePaths(groupsMerged) {
    const paths = groupsMerged.selectAll(`path.${this.styles.path.cssClass}`).data(d => [d]);

    paths.exit().remove();

    const pathsEnter = paths
      .enter()
      .append("path")
      .attr("class", this.styles.path.cssClass);

    paths
      .merge(pathsEnter)
      .attr("d", group => this.showBspline
          ? d3.line()
            .curve(d3.curveBasis)
            .x(d => this.xScale(d[this.xAxisProp]))
            .y(d => this.yScale(d[this.yAxisProp]))(group.values.filter(el => !isNaN(el.value) && el.value !== null))
          : d3.line()
            .x(d => this.xScale(d[this.xAxisProp]))
            .y(d => this.yScale(d[this.yAxisProp]))(group.values.filter(el => !isNaN(el.value) && el.value !== null))
      )
      .attr("fill", "none")
      .attr("stroke", group => this.palette[this.categories.indexOf(group.key) % this.palette.length])
      .attr("stroke-width", this.styles.path.strokeWidth)
      .attr("stroke-dasharray", (_, i, node) => {
        const totalLength = node[i].getTotalLength();
        return `${totalLength} ${totalLength}`;
      })
      .attr("stroke-dashoffset", (_, i, node) => {
        return node[i].getTotalLength();
      })
      .transition()
      .duration(this.transitionTime)
      .attr("stroke-dashoffset", 0)
      .attr("cursor", "pointer")
  }

  handleStacked(groupsMerged) {

    const serieStacked = this.aggregationProp === 'serie' ? 'serie' : this.props.pivotTableValue
    const keys = Array.from(group(this.data, d => d[serieStacked]).keys())

    const values = Array.from(rollup(this.data, ([d]) => d.value, d => +d.time, d => d[serieStacked]))

    const stackedData = d3.stack()
      .keys(keys)
      .value(([, values], serie) => values.get(serie))(values)
    const flatData = this.data.flat();
    const arrayScales = d3.extent(flatData, d => d[this.yAxisProp])
    const domainScalesLinear = arrayScales[1] > 1 ? [0, 100] : [0.001, 1]
    const areaBase = arrayScales[1] > 6 ? 0.1 : 0.001

    if(arrayScales[1] < 1) {
      this.g
        .select(".x.axis")
        .attr("transform", `translate(0,${this.height})`)
        .call(this.customXAxis.bind(this));
    }

    if(this.data[0].units === "%" && arrayScales[1] <= 1) {
      this.yScale = this.yScaleFunction(d3)
        .domain(domainScalesLinear)
        .range([this.height, 0])
    } else if(this.data[0].units === "%" && arrayScales[1] >= 1 && arrayScales[1] <= 6 || this.hasContainer) {
      this.yScale = this.yScaleFunction(d3)
        .domain([0.001, d3.max(stackedData, d => d3.max(d, d => d[1]))])
        .range([this.height, 0])
    } else {
      this.yScale = this.yScaleFunction(d3)
        .domain([0.1, d3.max(stackedData, d => d3.max(d, d => d[1]))])
        .range([this.height, 0])
    }

    this.g.select(".y.axis")
      .transition()
      .duration(300)
      .call(this.customYAxis.bind(this));

    const area = d3
      .area()
      .x((d) => this.xScale(d.data[0]))
      .y0((d) => this.yScale(d[0] + areaBase))
      .y1((d) => this.yScale(d[1]))

    const paths = groupsMerged.selectAll(`path.${this.styles.path.cssClass}`).data(stackedData);

    paths.exit().remove();

    const pathsEnter = paths
      .enter()
      .append("path")
      .attr("class", this.styles.path.cssClass);

    paths
      .merge(pathsEnter)
      .transition()
      .duration(200)
      .ease(d3.easeLinear)
      .attr("fill", d => this.palette[this.categories.indexOf(d.key) % this.palette.length])
      .attr("stroke", d => this.palette[this.categories.indexOf(d.key) % this.palette.length])
      .attr("d", area)


    setTimeout(() => {

      const labels = stackedData.map(d => {
        return {
          fx: 0,
          targetY: this.yScale(d[0][0] + 0.1)
        };
      });

      const forceClamp = (min, max) => {
        let nodes;
        const force = () => {
          nodes.forEach(n => {
            if (n.y > max) n.y = max;
            if (n.y < min) n.y = min;
          });
        };
        force.initialize = (_) => nodes = _;
        return force;
      }

      const force = d3.forceSimulation()
        .nodes(labels)
        .force('collide', d3.forceCollide(18 / 2))
        .force('y', d3.forceY(d => d.targetY).strength(1))
        .force('clamp', forceClamp(0, this.height))
        .stop();

      for (let i = 0; i < 300; i++) force.tick();

      stackedData.forEach((values, i) => values.y = labels[i].y);

      this.container
        .selectAll('.legend-item-right')
        .data(stackedData)
        .join('span.legend-item-right')
          .attr('class', 'legend-item-right')
          .attr('id', d => d.key)
          .style('position', 'absolute')
          .style('left', `${this.width + this.margin.left + 16}px`)
          .style('top', d => `${d.y + this.margin.top - 5}px `)
          .style('color', d => this.palette[this.categories.indexOf(d.key) % this.palette.length])
          .html(d => {
            return self.aggregationProp === self.props.pivotTableValue ? `<span class="legend-item-right-text">${d.values[0][self.props.pivotTableValue]}</span>` : `<span class="legend-item-right-text">${d.values[0].name}</span>`
          })

      this.container
        .selectAll('.close')
        .data(stackedData)
        .style('position', 'absolute')
        .style('left', function() {
          return self.isMobile ? `${self.width}px` : `${self.width + self.margin.left + 136}px`
        })
        .style('top', d => `${d.y + this.margin.top - 5}px `)
        .style("background-color", d => this.hexToRGB(this.palette[this.categories.indexOf(d.key) % this.palette.length], 0.5))
    }, 150);
  }

  onMouseenter(selection) {
    this.g.selectAll(`path.${this.styles.path.cssClass}`).attr("opacity", (_, i) => (i === selection ? 1 : 0.1));
  }

  onMouseout() {
    this.g.selectAll(`path.${this.styles.path.cssClass}`).attr("opacity", (_, i) => this.handleOpacity(i));
  }

  onClick(selection, status) {
    if (status) {
      this.currentHighlights = typeof this.currentHighlights !== "object" ? {} : this.currentHighlights;
      this.currentHighlights[selection] = status;
    } else {
      delete this.currentHighlights[selection];
    }

    this.g
      .selectAll(`path.${this.styles.path.cssClass}`)
      .transition()
      .attr("opacity", (_, i) => ((i === selection && this.currentHighlights[selection]) || this.currentHighlights[i] ? 1 : 0.1));
  }

  handleOpacity(d) {
    // No object
    if (!this.currentHighlights) return 1;
    // Empty object
    if (Object.keys(this.currentHighlights).length === 0 && this.currentHighlights.constructor === Object) return 1;
    // Selected property
    if (this.currentHighlights[d]) return 1;
    // Otherwise
    return 0.1;
  }

  customXAxis(g) {
    const format = (...args) =>
      this.tickFormatX ? this.tickFormatX.apply(null, [...args, d3]) : this.defaultTickFormatX.apply(null, args);
    g.call(
      d3
      .axisBottom(this.xScale)
      .tickPadding(this.styles.circle.radius * 1.5)
      .ticks(this.ticksXAxis)
      .tickSize(-this.height)
      .tickFormat(format)
    );
    g.selectAll(".domain").remove();
    g.selectAll(".x.axis line")
      .attr("stroke", this.styles.axis.x.stroke)
      .attr("stroke-dasharray", this.styles.axis.x.strokeDasharray);
    g.selectAll(".x.axis text")
      .attr("fill", this.styles.axis.x.fill)
      .attr("font-size", this.styles.axis.x.fontSize)
      .attr("font-weight", this.styles.axis.x.fontWeight);
  }

  customYAxis(g) {

    const locale = d3.formatDefaultLocale({
      "decimal": ",",
      "thousands": ".",
      "grouping": [3],
    });

    //Some Yscales values are between 0 and 1, for them we'll show two decimals in the text
    const flatData = this.data.flat();
    const arrayScales = d3.extent(flatData, d => d[this.yAxisProp])
    const format = arrayScales[1] > 1 ? locale.format(',.1s') : locale.format(',.2f')

    if (this.yScaleFunctionString.includes('Math.E')) {
      this.ticksYAxis = 10
    }

    g.call(
      d3
      .axisLeft(this.yScale)
      .tickSize(-this.width)
      .tickArguments([this.ticksYAxis, format])
    );

    g.selectAll(".domain").remove();
    g.selectAll(".y.axis line")
      .attr("stroke", this.styles.axis.y.stroke)
      .attr("stroke-dasharray", this.styles.axis.y.strokeDasharray);

    g.selectAll(".y.axis text")
      .attr("fill", this.styles.axis.y.fill)
      .attr("font-size", this.styles.axis.y.fontSize)
      .attr("font-weight", this.styles.axis.y.fontWeight)
      .attr("text-anchor", this.isMobile ? "end" : null)
      .attr("dy", this.isMobile ? "-0.55em" : "0.32em");
  }

  defaultTooltip(d) {
    return `<pre>${JSON.stringify(d, null, 2)}</pre>`;
  }

  defaultStyles(styles = {}) {
    return {
      path: {
        cssClass: "path",
        strokeWidth: 2,
        ...styles.path
      },
      circle: {
        cssClass: "circle",
        radius: 5,
        fill: "transparent",
        stroke: "none",
        ...styles.circle
      },
      axis: {
        x: {
          stroke: "#dcdcdc",
          strokeDasharray: "2, 2",
          fill: "#a5a5a5",
          fontSize: "1em",
          fontWeight: "bold",
          ...(styles.axis || {}).x
        },
        y: {
          stroke: "#dcdcdc",
          strokeDasharray: "2, 2",
          fill: "#a5a5a5",
          fontSize: "1em",
          fontWeight: "bold",
          ...(styles.axis || {}).y
        }
      }
    };
  }
}
