<template>
  <div>
    <div class="chart-wrapper">
      <div
        id="heat-map"
        ref="chart"
      >
      </div>
    </div>
  </div>
</template>
<script>
import { VisualizationMixin } from "../../assets/mixins";

import HeatMap from "../../assets/charts/HeatMap";

export default {
  name: "HeatMap",
  mixins: [VisualizationMixin],
  data() {
    return {
      data: [],
      chart: null
    };
  },
  props: {
    properties: Object,
    container: {
      type: Boolean,
      default: false
    }
  },
  async mounted() {
    const props = {
      ...this.properties,
      ctx: this.$refs.chart,
      data: this.data
    };
    this.chart = new HeatMap(props);

    await this.setChartData();

  },
  methods: {
   async setChartData() {
     const data = await this.fetchData();
     this.chart.data = data
     this.data = data
     this.chart.draw();
   }
  }
};
</script>
<style>
#heat-map {
  width: 100%;
  position: relative;
  border-top: 1px solid rgba(151, 151, 151, .2);
  border-bottom: 1px solid rgba(151, 151, 151, .2);
  padding: 1rem;
  margin: 0 auto;
  box-sizing: border-box;
}

.chart-container {
  display: flex;
  flex-direction: row;
  gap: .5rem;
}

.countries-column__element{
  display: flex;
  align-items: center;
  margin: .25rem 0;
  box-sizing: unset;
}

.countries-columns,
.countries-data-column{
  flex: 1;
}

.countries-column .countries-column__element{
  display: block;
  text-align: right;
  height: 1.5rem;
  margin-bottom: .25rem;
  padding-top: 0;
  margin-top: 0;
}

.countries-column .countries-column__element-text{
  padding: 0;
}

.countries-column{
  margin-top: auto;
}

.countries-column .developing-country,
.countries-column .developed-country {
  padding-left: .5rem;
  padding-right: .5rem;
  background-color: rgba(0, 185, 250, 0.25);
}

.countries-column .developing-country{
  background-color: rgba(255, 178, 25, 0.25);
}

.countries-data-column .countries-column__element,
.countries-data-column__header{
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: .25rem;
}

.countries-data-column__header{
  position: sticky;
  background: rgba(255,255,255,.95);
  top: 0;
  padding-top: .5rem;
}

.countries-columns .countries-column__element-text{
  text-align: right;
}

.countries-column__element-text,
.countries-data-column__header-text {
  text-align: center;
  display: inline-block;
  padding: .25rem;
  border-radius: .25rem;
  font-size: 12px;
  min-height: 1rem;
}

.countries-data-column__header-text {
  padding-bottom: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.countries-column__element-text{
  cursor: pointer;
}

.countries-columns .countries-column__element-text{
  text-align: left;
}

.countries-data-column__header-title {
  grid-column: span 2;
  text-align: center;
  font-size: 12px;
  font-weight: bold;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.tooltip-heatmap {
  width: max-content;
  background-color: #fff;
  position: absolute;
  display: none;
  opacity: 0;
  border: 1px solid #CACACA;
  padding: .5rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, .14);
  border-radius: 2px;
  box-sizing: border-box;
}

.tooltip-title {
  font-weight: bold;
  display: inline-block;
}

.legend-scale {
  margin-top: 2.75rem;
  margin-left: 1rem;
  position: sticky;
  top: 0;
}

.legend-scale text {
  font-family: 'Libre Franklin', sans-serif;
}

.legend-scale .tick line {
  stroke: #000;
}

.countries-column-legends{
  margin-bottom: .75rem;
}

.countries-column-legends__element{
  display: flex;
  align-items: center;
  gap: .25rem;
  margin-bottom: .5rem;
}

.countries-column-legends__element .countries-column-legends__element-legend{
  display: inline-block;
  width: .75rem;
  height: .75rem;
  padding: 0;
  border: 1px solid #111;
}

.countries-column-legends__element .countries-column-legends__element-text{
  font-size: 11px;
  text-transform: capitalize;
}
</style>
