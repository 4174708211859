<template>
  <div class="map-container-breadcrumb">
    <template v-if="!showMap">
      <a
        @click="handleMap"
        class="map-container-breadcrumb__first map-container-breadcrumb__first__link"
      >
        {{ $t('home') }}
      </a>
      <v-icon
        name="chevron-right"
        scale="0.75"
      />
      <span class="map-container-breadcrumb__last">
        {{ breadCrumbLast }}
      </span>
    </template>
  </div>
</template>
<i18n src="../../locales/breadCrumb.json"></i18n>
<script>
import "vue-awesome/icons/chevron-right";
import Icon from "vue-awesome/components/Icon";
export default {
  name: "BreadCrumb",
  components: {
    "v-icon": Icon
  },
  props: {
    breadCrumbLast: {
      type: String,
      default: ""
    },
    language: {
      type: String,
      default: ""
    },
    showMap: {
      type: Boolean,
      default: true
    }
  },
  created() {
    this.$i18n.locale = this.language
  },
  methods: {
    handleMap() {
      this.$emit('handleMap')
    }
  }
};
</script>
<style>
.map-container-breadcrumb {
  margin-bottom: .75rem;
}

.map-container-breadcrumb__first,
.map-container-breadcrumb__last {
  font-size: 18px;
  font-weight: bold;
  display: inline-block;
}

.map-container-breadcrumb__first,
.map-container-breadcrumb__first__link {
  padding-right: .5rem;
}

.map-container-breadcrumb__last {
  padding-left: .5rem;
}

.map-container-breadcrumb__first__link {
  color: #558380;
  cursor: pointer;
  text-decoration: underline;
}

</style>
